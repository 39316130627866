export const QuizEnglish = {
  additionalResources: 'Additional resources',
  quiz: 'Quiz',
  summary: 'You have to spend about {minutes} of your uninterrupted time to take the quiz and complete your application.',
  aboutTheTest: 'About the test',
  instructions: 'Instructions',
  setARemainder: 'Set a reminder',
  startQuiz: 'Start quiz',
  quizConfirmation: 'Quiz confirmation',
  quizConfirmCheckboxLabel: 'I understand that I have to complete the quiz in the next {minutes} to avoid rejection of my application.',
  pleaseConfirm: 'Please confirm',
  quizResume: {
    completeToSubmit: 'Complete the quiz to submit your application',
    receivedPartially: 'Your application for {roleName} is received partially. Complete the quiz to submit your application.'
  },
  timeout: {
    outOfTime: 'Time ran out for that question',
    instructions: 'For the rest of the questions, try to make your best guess in the time given. You can keep an eye on the timer in the top right corner.'
  },
  submitTimeout: {
    submit: 'Your quiz is auto submitted because of timeout.'
  },
  reportDialog: {
    reportProblem: 'Report problem',
    selectCategory: 'Select a category for this issue',
    provideDetails: 'Please provide the detailed description if possible'
  },
  reportProblem: 'Report a problem',
  submissionReceived: 'Your submission is received.',
  instructionsFields: {
    estimatedTime: 'Estimated time',
    format: 'Format',
    totalQuestions: 'Total number of questions',
    aboutQuiz: 'This quiz is built to test the basics required for this role.',
    dontNavigateToOther: 'Don’t navigate to other tabs or search the web while taking the test.',
    useBrowser: 'Use a regular browser window (no incognito mode).',
    bestGuess: 'Make your best guess on every question, using as much of the provided time as you like. Your score is not impacted by how fast you answer.',
    unanswerdQuestions: 'Unanswered questions are considered incorrect.'
  },
  note: {
    minAndMaxTime: 'The quiz only takes {minTime} to complete, but if you are interrupted, you can come back to it within {maxTime}.',
    goBack: 'Go back if you don\'t have uninterrupted {minutes} or if you want to update your application.'
  },
  answerResponse: {
    submit: 'Submit',
    selectYourAnswer: 'Select your answer',
    chooseTheAnswer: 'Choose the answer'
  },
  errorMessage: {
    select: 'Please select',
    chooseAnswer: 'Please choose the answer before submitting',
    reviewSubmitted: 'Your review is submitted. We will try to resolve it earliest.',
    answerSubmitted: 'Answer submitted successfully.',
    quizCompleted: 'Your quiz is completed.'
  },
  completed: {
    applicationReceived: 'Your application for {roleName} is received'
  }
}

export const QuizArabic = {
  additionalResources: 'الموارد الإضافية',
  quiz: 'اختبار',
  summary: 'يجب أن تنفق حوالي {minutes} من وقتك دون انقطاع لتأخذ الاختبار وتكمل طلبك.',
  aboutTheTest: 'حول الاختبار',
  instructions: 'تعليمات',
  setARemainder: 'ضع تذكيرًا',
  startQuiz: 'بدء الاختبار',
  quizConfirmation: 'تأكيد الاختبار',
  quizConfirmCheckboxLabel: 'أفهم أنه يجب عليّ إكمال الاختبار في الـ {minutes} القادمة لتجنب رفض طلبي.',
  pleaseConfirm: 'الرجاء التأكيد',
  quizResume: {
    completeToSubmit: 'أكمل الاختبار لتقديم طلبك',
    receivedPartially: 'تم استلام طلبك للمنصب {roleName} جزئيًا. أكمل الاختبار لتقديم طلبك.'
  },
  timeout: {
    outOfTime: 'انتهى الوقت لهذا السؤال',
    instructions: 'بالنسبة لبقية الأسئلة، حاول قدر الإمكان التخمين الأفضل في الوقت المعطى. يمكنك مراقبة المؤقت في الزاوية اليمنى العلوية.'
  },
  submitTimeout: {
    submit: 'تم تقديم اختبارك تلقائيًا بسبب انتهاء الوقت.'
  },
  reportDialog: {
    reportProblem: 'الإبلاغ عن مشكلة',
    selectCategory: 'حدد فئة لهذه المشكلة',
    provideDetails: 'يرجى تقديم وصف مفصل إذا كان ذلك ممكنًا'
  },
  reportProblem: 'الإبلاغ عن مشكلة',
  submissionReceived: 'تم استلام تقديمك.',
  instructionsFields: {
    estimatedTime: 'الوقت المقدر',
    format: 'التنسيق',
    totalQuestions: 'إجمالي عدد الأسئلة',
    aboutQuiz: 'تم بناء هذا الاختبار لاختبار الأساسيات المطلوبة لهذا الدور.',
    dontNavigateToOther: 'لا تنتقل إلى علامات تبويب أخرى أو تبحث في الويب أثناء أداء الاختبار.',
    useBrowser: 'استخدم نافذة متصفح عادية (بدون وضع التصفح الخفي).',
    bestGuess: 'قم بتخمين أفضل إجابة على كل سؤال، باستخدام الوقت المقدم بما تشاء. لا يتأثر درجتك بمدى سرعة إجابتك.',
    unanswerdQuestions: 'يعتبر عدم الرد على الأسئلة غير صحيح.'
  },
  note: {
    minAndMaxTime: 'الاختبار يستغرق فقط {minTime} للانتهاء منه، ولكن إذا تعرضت للانقطاع، يمكنك العودة إليه خلال {maxTime}.',
    goBack: 'ارجع إذا لم تكن لديك {minutes} من الوقت دون انقطاع أو إذا كنت ترغب في تحديث طلبك.'
  },
  answerResponse: {
    submit: 'تقديم',
    selectYourAnswer: 'اختر إجابتك',
    chooseTheAnswer: 'اختر الاجابة'
  },
  errorMessage: {
    select: 'يرجى التحديد',
    chooseAnswer: 'يرجى اختيار الإجابة قبل التقديم',
    reviewSubmitted: 'تم تقديم مراجعتك. سنحاول حلها في أقرب وقت ممكن.',
    answerSubmitted: 'تم تقديم الإجابة بنجاح.',
    quizCompleted: 'اكتمل الاختبار الخاص بك.'
  },
  completed: {
    applicationReceived: 'تم استلام طلبك للمنصب {roleName}'
  }
}
