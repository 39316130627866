<template>
  <div class="flex flex-col gap-2 items-start justify-start w-1/4 relative sidebar">
    <div v-permissions="getCompanySectionPermissions" class="border-solid border-[#e9e9ec] pt-0 pr-0 pb-3 pl-0 flex flex-col gap-6 items-start justify-start self-stretch shrink-0 relative" style="border-width: 0 0 1px 0">
      <div
        class="relative self-stretch" style="font: 600 14px/20px 'Inter', sans-serif; color: #010113">
        {{$t('recruiterSettings.company')}}
      </div>
      <div class="flex flex-col items-start justify-start self-stretch shrink-0 relative">
        <LinkButton
          v-permissions="getCareerBrandPagePermissions"
          :link="{
            name: $t('recruiterSettings.companyBrand'),
            to: `/recruiter-settings/brands`,
            active: 'brands',
            class: 'p-2',
            inputClass: 'text-14px font-weight-500 no-underline text-wcDarkGray'
          }" class="text-left relative"
        />
        <LinkButton
          v-permissions="getTeamsPagePermissions"
          data-testid="team-members" :link="{
          name: $t('recruiterSettings.teamMembers'),
          to: `/recruiter-settings/teams`,
          active: 'teams',
          class: 'p-2',
          inputClass: 'text-14px font-weight-500 no-underline text-wcDarkGray'
        }" class="text-left relative"
        />
        <LinkButton
          v-permissions="getLocationAndDepartmentPermissions"
          data-testid="company-locations" :link="{
          name: $t('recruiterSettings.locationAndDepartments'),
          to: `/recruiter-settings/locations-departments`,
          active: 'locations-departments',
          class: 'p-2',
          inputClass: 'text-14px font-weight-500 no-underline text-wcDarkGray'
        }" class="text-left relative"
        />
      </div>
    </div>
    <div v-permissions="getRecruitingPermissions" class="border-solid border-[#e9e9ec] pt-3 pr-0 pb-3 pl-0 flex flex-col gap-6 items-start justify-start self-stretch shrink-0 relative" style="border-width: 0px 0px 1px 0px">
      <div class="text-left relative" style="font:  600 14px/20px 'Inter', sans-serif; color: #010113">
        {{$t('recruiterSettings.recruiting')}}
      </div>
      <div class="flex flex-col items-start justify-start self-stretch shrink-0 relative">
        <LinkButton
          v-permissions="stagePipelineArchivalReasonPermissions"
          :link="{
          name: $t('recruiterSettings.defaultPipelineAndArchivalReasons'),
          to: `/recruiter-settings/stage-pipeline`,
          active: 'stage-pipeline',
          class: 'p-2',
          inputClass: 'text-14px font-weight-500 no-underline text-wcDarkGray'
        }" class="text-left relative"
        />
        <LinkButton
          v-permissions="getCompanyBookingLinkManagePermissions"
          :link="{
            name: $t('recruiterSettings.interviewBookingLinks'),
            to: `/recruiter-settings/interview-booking`,
            active: 'interview-booking',
            class: 'p-2',
            inputClass: 'text-14px font-weight-500 no-underline text-wcDarkGray'
          }"
          class="text-left relative"
        />
        <LinkButton
          v-permissions="getInterviewFeedbackTemplateManagePermissions"
          :link="{
            name: $t('recruiterSettings.interviewScorecardTemplates'),
            to: `/recruiter-settings/interview-scorecard-templates`,
            active: 'interview-scorecard-templates',
            class: 'p-2',
            inputClass: 'text-14px font-weight-500 no-underline text-wcDarkGray'
          }"
          class="text-left relative"
        />
        <LinkButton
          v-permissions="getCompanyEmailTemplateManagePermissions"
          :link="{
          name: $t('recruiterSettings.emailTemplates'),
          to: `/recruiter-settings/manage-emails`,
          active: 'manage-emails',
          class: 'p-2',
          inputClass: 'text-14px font-weight-500 no-underline text-wcDarkGray'
        }" class="text-left relative"
        />
        <LinkButton
          v-permissions="getOfferLetterTemplateManagePermissions"
          :link="{
          name: $t('listOffers.title'),
          to: `/recruiter-settings/offers`,
          active: 'offers',
          class: 'p-2',
          inputClass: 'text-14px font-weight-500 no-underline text-wcDarkGray'
        }" class="text-left relative"
        />
        <LinkButton
          v-permissions="getCompanyHrmsIntegrationManagePermissions"
          v-if="canShowIntegrationsRoute()" :link="{
          name: $t('recruiterSettings.integrations'),
          to: `/recruiter-settings/company-integrations`,
          active: 'company-integrations',
          class: 'p-2',
          inputClass: 'text-14px font-weight-500 no-underline text-wcDarkGray'
        }" class="text-left relative"
          />
        <LinkButton
          v-permissions="getAgenciesManagePermissions"
          :link="{
          name: $t('recruiterSettings.agencies'),
          to: `/recruiter-settings/agencies`,
          active: 'agencies',
          class: 'p-2',
          inputClass: 'text-14px font-weight-500 no-underline text-wcDarkGray'
        }" class="text-left relative"
        />
      </div>
    </div>

    <div class="pt-3 pr-0 pb-3 pl-0 flex flex-col gap-6 items-start justify-start self-stretch shrink-0 relative">
      <div class="text-left relative self-stretch" style="font: 600 14px/20px 'Inter', sans-serif; color: #010113">
        {{$t('recruiterSettings.personal')}}
      </div>

      <div class="flex flex-col items-start justify-start self-stretch shrink-0 relative">
        <LinkButton
          :link="{
            name: $t('recruiterSettings.profile'),
            to: `/recruiter-settings/profile`,
            active: 'profile',
            class: 'p-2',
            inputClass: 'text-14px font-weight-500 no-underline text-wcDarkGray'
          }"
          class="text-left relative"
        />
        <LinkButton
          v-permissions="getCalendarEmailIntegrationsManagePermissions"
          :link="{
            name: $t('recruiterSettings.calendarAndEmailIntegration'),
            to: `/recruiter-settings/app-integration`,
            active: 'app-integration',
            class: 'p-2',
            inputClass: 'text-14px font-weight-500 no-underline text-wcDarkGray'
          }"
          class="text-left relative"
        />
        <LinkButton
          v-permissions="getNotificationPermissions"
          :link="{
            name: $t('recruiterSettings.notificationPreferences'),
            to: `/recruiter-settings/notification-preferences`,
            active: 'notification-preferences',
            class: 'p-2',
            inputClass: 'text-14px font-weight-500 no-underline text-wcDarkGray'
          }"
          class="text-left relative"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { UserModule } from '@/store/modules/user'
import { JobRoleAccessTypeEnum } from '@/views/recruiter/job-role/models/enums'
import { CompanyModule } from '@/store/modules/company'
import {
  CAREER_BRAND_PAGE_PERMISSIONS,
  COMPANY_AGENCY_MANAGE_PERMISSIONS,
  COMPANY_ARCHIVAL_REASONS_MANAGE_PERMISSIONS,
  COMPANY_BOOKING_LINKS_MANAGE,
  USER_CALENDAR_EMAIL_INTEGRATIONS_MANAGE,
  COMPANY_EMAIL_TEMPLATES_MANAGE,
  COMPANY_HRMS_INTEGRATIONS_MANAGE,
  COMPANY_INTERVIEW_FEEDBACK_TEMPLATE_MANAGE,
  COMPANY_LOCATIONS_DEPARTMENTS_MANAGE,
  COMPANY_OFFER_LETTER_TEMPLATE_MANAGE,
  COMPANY_STAGE_PIPELINE_MANAGE_PERMISSIONS,
  COMPANY_TEAM_MEMBERS_MANAGE,
  USER_NOTIFICATIONS_MANAGE
} from '@/permissions/permissions-constants'
import { PermissionsEnum } from '@/permissions/permissions-enum'

export default {
  name: 'RecruiterSettings',
  computed: {
    PermissionsEnum() {
      return PermissionsEnum
    },
    JobRoleAccessTypeEnum() {
      return JobRoleAccessTypeEnum
    },
    getCareerBrandPagePermissions() {
      return CAREER_BRAND_PAGE_PERMISSIONS
    },
    getTeamsPagePermissions() {
      return COMPANY_TEAM_MEMBERS_MANAGE
    },
    getLocationAndDepartmentPermissions() {
      return COMPANY_LOCATIONS_DEPARTMENTS_MANAGE
    },
    getCompanySectionPermissions() {
      return [
        ...CAREER_BRAND_PAGE_PERMISSIONS,
        ...COMPANY_TEAM_MEMBERS_MANAGE,
        ...COMPANY_LOCATIONS_DEPARTMENTS_MANAGE
      ]
    },
    stagePipelineArchivalReasonPermissions() {
      return [...COMPANY_STAGE_PIPELINE_MANAGE_PERMISSIONS, ...COMPANY_ARCHIVAL_REASONS_MANAGE_PERMISSIONS]
    },
    getInterviewFeedbackTemplateManagePermissions() {
      return COMPANY_INTERVIEW_FEEDBACK_TEMPLATE_MANAGE
    },
    getOfferLetterTemplateManagePermissions() {
      return COMPANY_OFFER_LETTER_TEMPLATE_MANAGE
    },
    getCompanyEmailTemplateManagePermissions() {
      return COMPANY_EMAIL_TEMPLATES_MANAGE
    },
    getAgenciesManagePermissions() {
      return COMPANY_AGENCY_MANAGE_PERMISSIONS
    },
    getNotificationPermissions() {
      return USER_NOTIFICATIONS_MANAGE
    },
    getCompanyBookingLinkManagePermissions() {
      return COMPANY_BOOKING_LINKS_MANAGE
    },
    getCompanyHrmsIntegrationManagePermissions() {
      return COMPANY_HRMS_INTEGRATIONS_MANAGE
    },
    getCalendarEmailIntegrationsManagePermissions() {
      return USER_CALENDAR_EMAIL_INTEGRATIONS_MANAGE
    },
    getRecruitingPermissions() {
      return [
        ...this.stagePipelineArchivalReasonPermissions,
        ...this.getCompanyBookingLinkManagePermissions,
        ...this.getInterviewFeedbackTemplateManagePermissions,
        ...this.getCompanyEmailTemplateManagePermissions,
        ...this.getOfferLetterTemplateManagePermissions,
        ...this.getCompanyHrmsIntegrationManagePermissions,
        ...this.getAgenciesManagePermissions
      ]
    }
  },
  props: {},
  data() {
    return {
      companyId: ''
    }
  },
  mounted() {
    this.initSidebar()
  },
  methods: {
    CompanyModule() {
      return CompanyModule
    },
    UserModule() {
      return UserModule
    },
    initSidebar() {
      this.companyId = UserModule.companyId || ''
    },
    fullAccessUser() {
      return UserModule.access === JobRoleAccessTypeEnum.FULL_ACCESS
    },
    canShowIntegrationsRoute() {
      return this.fullAccessUser()
    }
  }
}
</script>
<style lang="scss">
.sidebar{
  min-width: 20rem;
}
</style>
