export const LocationsAndDepartmentsEnglish = {
  title: 'Locations and departments',
  subTitle: 'Manage your office locations and departments that you can add while creating a job',
  tooltipText: 'Click here to learn more about locations and departments',
  locations: {
    addNewLocation: 'Add new location',
    description: 'Save your office locations here to add them while creating a job description',
    subTitle: 'Locations',
    locationName: 'Location name',
    addLocationName: 'Enter city, country',
    address: 'Address',
    enterAddress: 'Enter address',
    country: 'Country',
    chooseCountry: 'Choose country',
    chooseStateRegion: 'Choose state or region or province',
    enterZipPostalCode: 'Enter zip code or postal code',
    zipPostalCode: 'Zip/Postal code',
    enterValidInput: 'Please enter valid input',
    pleaseEnterLocationName: 'Please enter location name',
    chooseCity: 'Choose city',
    pleaseSelectCountry: 'Please select country',
    pleaseSelectState: 'Please select state',
    pleaseSelectCity: 'Please select city',
    noStatesFound: 'No states found',
    noCitiesFound: 'No cities found',
    chooseStateToViewCities: 'Choose state to view cities',
    chooseCountryToViewStates: 'Choose country to view states',
    infoForJobBoards: 'In order for job postings associated with this location to show up in google, job board search results, make sure that City, State and Country are specified.',
    city: 'City',
    state: 'State',
    cannotDelete: 'Jobs are linked to this location. You can’t delete it.',
    areYouSureToDelete: 'Are you sure you want to delete location',
    addressNote: 'Add an address to display this location when scheduling an in-person interview',
    noLocationAdded: 'No locations added',
    messages: {
      locationDeletedSuccessfully: 'Successfully deleted location',
      locationUpdatedSuccessfully: 'Successfully updated location',
      locationCreatedSuccessfully: 'Successfully created location'
    }
  },
  departments: {
    addNewDepartment: 'Add new department',
    description: 'Save your departments here to add them while creating a job description',
    subTitle: 'Departments',
    departmentName: 'Department name',
    addDepartmentName: 'Add department name',
    headOfDept: 'Head of department',
    selectHeadOfDept: 'Select head of department',
    cannotDelete: 'Jobs are linked to this department. You can\'t delete it',
    areYouSureToDelete: 'Are you sure you want to delete department',
    noDepartmentsAdded: 'No departments added',
    messages: {
      departmentDeletedSuccessfully: 'Successfully deleted department',
      departmentUpdatedSuccessfully: 'Successfully updated department',
      departmentCreatedSuccessfully: 'Successfully created department'
    }
  }
}

export const LocationsAndDepartmentsArabic = {
  title: 'المكاتب والإدارات',
  subTitle: 'قم بإدارة مواقع المكاتب والإدارات التي يمكنك إضافتها أثناء إنشاء وظيفة.',
  tooltipText: 'انقر هنا لمعرفة المزيد عن المواقع والإدارات',
  locations: {
    addNewLocation: 'أضف موقع جديد',
    description: 'احفظ مواقع مكاتبك هنا لإضافتها عند إنشاء وصف وظيفي.',
    subTitle: 'المكاتب',
    locationName: 'اسم الموقع',
    address: 'العنوان',
    enterAddress: 'أدخل العنوان',
    country: 'البلد',
    chooseCountry: 'اختر البلد',
    chooseStateRegion: 'اختر الولاية أو المنطقة أو المقاطعة',
    enterZipPostalCode: 'أدخل الرمز البريدي أو الكود البريدي',
    zipPostalCode: 'الرمز البريدي/الكود البريدي',
    enterValidInput: 'يرجى إدخال مدخل صالح',
    pleaseEnterLocationName: 'يرجى إدخال اسم الموقع',
    chooseCity: 'اختر المدينة',
    pleaseSelectCountry: 'يرجى اختيار البلد',
    pleaseSelectState: 'يرجى اختيار الولاية',
    pleaseSelectCity: 'يرجى اختيار المدينة',
    noStatesFound: 'لم يتم العثور على ولايات',
    noCitiesFound: 'لم يتم العثور على مدن',
    chooseStateToViewCities: 'اختر الولاية لعرض المدن',
    chooseCountryToViewStates: 'اختر البلد لعرض الولايات',
    infoForJobBoards: 'لكي تظهر الإعلانات الوظيفية المرتبطة بهذا الموقع في نتائج البحث في google أو نتائج البحث في لوحات الوظائف، تأكد من تحديد المدينة، الولاية والبلد.',
    city: 'المدينة',
    state: 'الولاية',
    addLocationName: 'أضف اسم الموقع',
    addAddress: 'أضف العنوان',
    cannotDelete: 'الوظائف مرتبطة بهذا الموقع. لا يمكنك حذفه.',
    updatedLocation: 'تم تحديث الموقع بنجاح',
    createdLocation: 'تم إنشاء الموقع بنجاح',
    areYouSureToDelete: 'هل أنت متأكد أنك تريد حذف الموقع؟',
    addressNote: 'أضف عنوانًا لعرض هذا الموقع عند جدولة مقابلة شخصية.',
    noLocationAdded: 'لم يتم إضافة مواقع',
    messages: {
      locationDeletedSuccessfully: 'تم حذف الموقع بنجاح',
      locationUpdatedSuccessfully: 'تم تحديث الموقع بنجاح',
      locationCreatedSuccessfully: 'تم إنشاء الموقع بنجاح'
    }
  },
  departments: {
    addNewDepartment: 'أضف قسم جديد',
    description: 'احفظ أقسامك هنا لإضافتها عند إنشاء وصف وظيفي.',
    subTitle: 'الأقسام',
    departmentName: 'اسم القسم',
    addDepartmentName: 'أضف اسم القسم',
    headOfDept: 'رئيس القسم',
    selectHeadOfDept: 'اختر رئيس القسم',
    cannotDelete: 'الوظائف مرتبطة بهذا القسم، لا يمكنك حذفه',
    areYouSureToDelete: 'هل أنت متأكد أنك تريد حذف القسم؟',
    noDepartmentsAdded: 'لم يتم إضافة أقسام',
    messages: {
      departmentDeletedSuccessfully: 'تم حذف القسم بنجاح',
      departmentUpdatedSuccessfully: 'تم تحديث القسم بنجاح',
      departmentCreatedSuccessfully: 'تم إنشاء القسم بنجاح'
    }
  }
}
