export const JobBoardIntegrationsEnglish = {
  linkedin: {
    linkedInIntegration: 'LinkedIn integration',
    integrationTypeCannotBeEmpty: 'Integration type cannot be empty',
    howDoYouWantToPublish: 'How do you want to publish jobs on LinkedIn?',
    clickHereToKnowLinkedInCompanyId: 'Click here to learn how to find LinkedIn company ID',
    postAllJobs: 'Post all my external jobs by default',
    postSelectedJobs: 'Decide when I publish a job',
    enterMandatoryLocationDetails: 'Please enter mandatory location details to post on linkedIn',
    updateAndPost: 'Update and post',
    jobsWithoutLocationDetails: 'jobs are missing the required country, city, and state details in their location fields.',
    pleaseUpdateJobLocations: 'Please update these locations to ensure the jobs can be posted on LinkedIn.',
    updateJobLocations: 'Update job locations to post on LinkedIn',
    postJobOnLinkedIn: 'Post jobs on LinkedIn',
    plusMore: '+ {count} more',
    allYourJobsGotPublishedOnLinkedIn: {
      s1: 'All your',
      s2: 'existing external jobs are posted on LinkedIn. Do you want to remove all of them?'
    },
    currentlyUnPostedJobs: {
      s1: 'There are ',
      s2: 'un-posted existing external jobs in your company.',
      s3: 'Do you want to post them on LinkedIn?'
    },
    doYouWantToPostExistingJobsOnLinkedIn: {
      s1: 'Do you want to post all your',
      s2: 'existing external jobs on LinkedIn?'
    },
    doYouWantToUnPostExistingJobsOnLinkedIn: {
      s1: 'Do you want to remove all your existing',
      s2: 'jobs from LinkedIn?'
    },
    areYouSure: {
      postAllJobs: {
        s1: 'Are you sure you want to post all your',
        s2: 'existing external jobs on LinkedIn?'
      },
      unPostAllJobs: {
        s1: 'Are you sure you want to remove all your',
        s2: 'existing external jobs from LinkedIn?'
      }
    },
    removeIntegrationSuccess: 'Successfully removed integration',
    saveSettings: 'Successfully saved',
    postOnLinkedIn: 'Post on LinkedIn',
    toPostOnLinkedInPostOnCareerPage: 'To post on LinkedIn, publish on career page first',
    confirmationOfPostingJob: 'Are you sure you want to post this job on LinkedIn?',
    confirmationOfRemovingJob: 'Are you sure you want to remove this job from LinkedIn?',
    noneOfYourJobsPublishedOnLinkedIn: 'None of your jobs posted on LinkedIn. Do you want to post them all?',
    cantSaveUntilActivateLinkedin: 'Can\'t save until you activate the LinkedIn integration',
    allJobsPublishedWithin24Hours: 'All jobs will be posted to LinkedIn within 24 hours',
    allJobsUnPublishedWithin24Hours: 'All jobs will be removed from LinkedIn within 24 hours',
    integrateAndPostToLinkedIn: 'Integrate & post',
    willBePublished: 'Will be published by within 24 hours',
    publishedOnLinkedIn: 'Successfully saved. This job will be posted on LinkedIn within 24 hours',
    unPublishedOnLinkedIn: 'Successfully saved. This job will be removed from LinkedIn within 24 hours',
    integrate: 'Integrate',
    publishOnLinkedIn: 'Post job on LinkedIn',
    removeJobFromLinkedIn: 'Remove job from LinkedIn',
    removeIntegration: 'Remove integration'
  },
  jobTarget: {
    jobTargetIntegration: 'Job target integration',
    integrateWithJobTarget: 'Integrate with job target',
    connectWithJobTarget: 'Connect with job target',
    viewAndPublishInOtherJobBoards: 'View/publish in other job boards',
    integrateJobTargetOnSettingsPage: 'Integrate with job target on settings page',
    notPostedInAnyJobBoards: 'Not posted in any job boards through job target',
    companyDetails: 'Company details',
    recruitersDetails: 'Recruiters details',
    companyLocationDetails: 'Company location details',
    table: {
      siteName: 'Site name',
      postedDate: 'Posted date',
      expiresAt: 'Expires at',
      link: 'Link',
      status: 'Status',
      email: 'Email',
      fullName: 'Full name',
      customTitle: 'Custom title',
      roleInJobTarget: 'Admin/recruiter'
    },
    form: {
      companyName: 'Company name',
      accountType: 'Account type',
      country: 'Country',
      state: 'State',
      city: 'City',
      postalCode: 'Postal code'
    },
    validations: {
      enterCompanyName: 'Please enter company name',
      chooseAccountType: 'Please choose account type',
      enterCity: 'Please enter city',
      enterState: 'Please enter state',
      chooseCountry: 'Please choose a country',
      enterPostalCode: 'Please enter postal code'
    },
    messages: {
      success: {
        integrated: 'Successfully integrated with job target',
        updatedCompanyDetails: 'Successfully updated company details'
      }
    }
  }
}

export const JobBoardIntegrationsArabic = {
  linkedin: {
    linkedInIntegration: 'تكامل LinkedIn',
    howDoYouWantToPublish: 'كيف تريد نشر الوظائف على LinkedIn؟',
    clickHereToKnowLinkedInCompanyId: 'اضغط هنا لمعرفة كيفية العثور على معرف شركة LinkedIn',
    integrationTypeCannotBeEmpty: 'نوع التكامل لا يمكن أن يكون فارغًا.',
    postAllJobs: 'نشر جميع الوظائف الخارجية الخاصة بي افتراضيًا.',
    postSelectedJobs: 'قرر متى أنشر وظيفة',
    enterMandatoryLocationDetails: 'يرجى إدخال تفاصيل الموقع الإلزامية للنشر على لينكد إن',
    updateAndPost: 'تحديث ونشر',
    jobsWithoutLocationDetails: 'الوظائف تفتقر إلى تفاصيل البلد، المدينة، والولاية المطلوبة في حقول الموقع.',
    pleaseUpdateJobLocations: 'يرجى تحديث هذه المواقع لضمان إمكانية نشر الوظائف على LinkedIn.',
    updateJobLocations: 'تحديث مواقع الوظائف للنشر على LinkedIn',
    postJobOnLinkedIn: 'نشر الوظائف على LinkedIn',
    plusMore: '+ {count} المزيد',
    allYourJobsGotPublishedOnLinkedIn: {
      s1: 'تم نشر جميع',
      s2: 'الوظائف الخارجية الموجودة على LinkedIn. هل تريد إزالتها جميعًا؟'
    },
    currentlyUnPostedJobs: {
      s1: 'يوجد',
      s2: 'وظائف خارجية لم تُنشر في شركتك.',
      s3: 'هل تريد نشرها على LinkedIn؟'
    },
    doYouWantToPostExistingJobsOnLinkedIn: {
      s1: 'هل تريد نشر جميع',
      s2: 'الوظائف الخارجية الموجودة على LinkedIn؟'
    },
    doYouWantToUnPostExistingJobsOnLinkedIn: {
      s1: 'هل تريد إزالة جميع الوظائف الخارجية الموجودة',
      s2: 'من LinkedIn؟'
    },
    areYouSure: {
      postAllJobs: {
        s1: 'هل أنت متأكد أنك تريد نشر جميع',
        s2: 'الوظائف الخارجية الموجودة على LinkedIn؟'
      },
      unPostAllJobs: {
        s1: 'هل أنت متأكد أنك تريد إزالة جميع',
        s2: 'الوظائف الخارجية الموجودة من LinkedIn؟'
      }
    },
    removeIntegrationSuccess: 'تم إزالة التكامل بنجاح',
    saveSettings: 'تم حفظ الإعدادات بنجاح',
    postOnLinkedIn: 'نشر على LinkedIn',
    toPostOnLinkedInPostOnCareerPage: 'لنشر على LinkedIn، قم بالنشر أولاً على صفحة الوظائف',
    confirmationOfPostingJob: 'هل أنت متأكد أنك تريد نشر هذه الوظيفة على LinkedIn؟',
    confirmationOfRemovingJob: 'هل أنت متأكد أنك تريد إزالة هذه الوظيفة من LinkedIn؟',
    noneOfYourJobsPublishedOnLinkedIn: 'لم يتم نشر أي من وظائفك على LinkedIn. هل تريد نشرها جميعًا؟',
    cantSaveUntilActivateLinkedin: 'لا يمكن الحفظ حتى تقوم بتفعيل تكامل LinkedIn.',
    allJobsPublishedWithin24Hours: 'سيتم نشر جميع الوظائف على LinkedIn خلال 24 ساعة',
    allJobsUnPublishedWithin24Hours: 'سيتم إزالة جميع الوظائف من LinkedIn خلال 24 ساعة',
    integrateAndPostToLinkedIn: 'تكامل وانشر',
    willBePublished: 'سيتم نشرها خلال 24 ساعة',
    publishedOnLinkedIn: 'تم الحفظ بنجاح. سيتم نشر هذه الوظيفة على LinkedIn خلال 24 ساعة',
    unPublishedOnLinkedIn: 'تم الحفظ بنجاح. سيتم إزالة هذه الوظيفة من LinkedIn خلال 24 ساعة',
    integrate: 'تكامل',
    publishOnLinkedIn: 'نشر الوظيفة على LinkedIn',
    removeJobFromLinkedIn: 'إزالة الوظيفة من LinkedIn',
    removeIntegration: 'إزالة التكامل'
  },
  jobTarget: {
    jobTargetIntegration: 'تكامل job target',
    integrateWithJobTarget: 'دمج مع job target',
    connectWithJobTarget: 'الاتصال بـ job target',
    viewAndPublishInOtherJobBoards: 'عرض/نشر في لوحات الوظائف الأخرى',
    integrateJobTargetOnSettingsPage: 'دمج مع job target في صفحة الإعدادات',
    notPostedInAnyJobBoards: 'لم يتم النشر في أي لوحات وظائف من خلال job target.',
    companyDetails: 'تفاصيل الشركة',
    recruitersDetails: 'تفاصيل المجندين',
    companyLocationDetails: 'تفاصيل موقع الشركة',
    table: {
      siteName: 'اسم الموقع',
      postedDate: 'تاريخ النشر',
      expiresAt: 'ينتهي في',
      link: 'الرابط',
      status: 'الحالة',
      email: 'البريد الإلكتروني',
      fullName: 'الاسم الكامل',
      customTitle: 'عنوان مخصص',
      roleInJobTarget: 'مشرف/مجند'
    },
    form: {
      companyName: 'اسم الشركة',
      accountType: 'نوع الحساب',
      country: 'الدولة',
      state: 'الولاية',
      city: 'المدينة',
      postalCode: 'الرمز البريدي'
    },
    validations: {
      enterCompanyName: 'يرجى إدخال اسم الشركة',
      chooseAccountType: 'يرجى اختيار نوع الحساب',
      enterCity: 'يرجى إدخال المدينة',
      enterState: 'يرجى إدخال الولاية',
      chooseCountry: 'يرجى اختيار دولة',
      enterPostalCode: 'يرجى إدخال الرمز البريدي'
    },
    messages: {
      success: {
        integrated: 'تم الدمج بنجاح مع job target',
        updatedCompanyDetails: 'تم تحديث تفاصيل الشركة بنجاح'
      }
    }
  }
}
