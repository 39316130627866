export const CandidateListEnglish = {
  title: 'Candidates',
  published: 'Published',
  drafted: 'Draft',
  closed: 'Closed',
  activeCount: 'Active',
  archiveCount: 'Archived',
  showAllJobs: 'Show all jobs',
  addNewCandidate: 'Add new candidate',
  startTypingNameOrEmailOrTag: 'Start typing name or email (min {length} chars)',
  countOfSelected: '{count} of {total} selected',
  selectAll: 'Select all {count}',
  changeOriginAndSource: 'Change origin and source | Change origin and source ({count})',
  addTags: 'Add tags | Add tags ({count})',
  removeTags: 'Remove tags | Remove tags ({count})',
  sendEmail: 'Send email',
  deleteApplications: 'Delete applications',
  archiveApplications: 'Archive applications',
  unArchiveApplications: 'Unarchive applications ({count}) | Unarchiving...',
  insteadAddTags: 'Instead add tags',
  insteadRemoveTags: 'Instead remove tags',
  frequentUsedTags: 'Frequently used tags',
  progressToNextStage: 'Progress to next stage',
  deleteApplication: 'Delete one application | Delete {count} applications',
  deleteApplicationNote: 'This action will delete the selected candidate applications. This action cannot be undone. Are you sure?',
  triggerEmailNote: 'This action might trigger an email to candidates if rejection automations are enabled.',
  archiveApplication: 'Archive candidate application ({count})',
  progressStage: 'Progress stage ({count})?',
  progressStageConfirmation: 'This action will progress the selected candidate to the {name} stage. Are you sure?',
  progressStageNote: 'Note: This will send an email to candidates if stage welcome mail is enabled.',
  selectedOutOf: '{selected} applications out of {total} are in same stage',
  progressLoading: 'Progress stage ({count}) | Progressing stage...',
  sendEmailTo: 'Send email ({to})',
  archivingAs: 'Archiving as {reason} ({count})',
  mailsAddedOutOf: '{count} applications out of {total} do not have emails added to their profile',
  clearSelection: 'Clear selection',
  sendEmailToUsersNote: 'This will send an email to all {count} candidates who have emails added to their profiles.',
  noCandidatesFound: 'No candidates found 🙂',
  noCandidatesFoundTitle: 'No candidates found',
  noCandidatesFoundDescription: 'All the candidates applied and added will show up here!',
  placeHolders: {
    searchByJobTitle: 'Search by job title | Jobs selected({count})',
    status: 'Status| Status({count})',
    archivalReasons: 'Archival reason | Archival reason({count})',
    origin: 'Origin | Origin({count})',
    source: 'Source | Source({count})',
    owners: 'Owners | Owners({count})',
    tag: 'Tag | Tag({count})',
    tagInput: 'Enter new or existing tags',
    tagRemoveInput: 'Select existing tags',
    selectOrigin: 'Select origin',
    enterSource: 'Enter source',
    selectArchivalReason: 'Select archival reason'
  },
  filters: {
    origin: 'Origin',
    source: 'Source',
    statuses: 'Status'
  },
  table: {
    header: {
      name: 'NAME',
      owner: '',
      jobTitle: 'JOB TITLE',
      stage: 'STAGE',
      activity: 'LATEST ACTIVITY | ARCHIVAL REASON',
      lastActivity: 'LAST ACTIVITY'
    }
  },
  warnings: {
    bulkArchivalPrefix: 'Bulk archival with job id {part}',
    bulkProgressPrefix: 'Bulk progress with job id {part}',
    bulkEmailsPrefix: 'Bulk emails with job id {part}',
    bulkUnArchivalPrefix: 'Bulk unarchival with job id {part}',
    bulkTags: 'Bulk tags with job id {part}',
    bulkQueued: '{prefix} is queued. We will notify you after completion'
  },
  errors: {
    pleaseInputSource: 'Please input source',
    pleaseInputTags: 'Please enter new or existing tags',
    pleaseInputOrigin: 'Please select origin',
    pleaseInputArchiveReason: 'Please select archive reason'
  },
  successMessages: {
    sourceOriginChanged: 'Source and origin changed successfully',
    addTags: 'Tags added successfully',
    removeTags: 'Tags removed successfully',
    applicationDeleted: 'Applications deleted successfully',
    archivedApplication: 'Archived the applications successfully',
    unarchivedApplications: 'Unarchived the applications successfully',
    progressed: 'Progressed candidates successfully',
    emailsSent: 'Emails are sent successfully',
    successMessages: 'Files added successfully',
    filesAdded: 'Files added successfully'
  },
  cvSearchDialog: {
    title: 'Search your CV database',
    subTitle: 'Find CVs that',
    addSearchTerm: 'Add search term',
    matchingCandidates: 'Matching candidates: ',
    filters: {
      includesAll: 'Includes all of',
      includesAny: 'Includes any of'
    }
  },
  cvSearch: 'CV search',
  latestActivity: {
    offerSent: 'Offer sent by {recruiterName} on {date}',
    offerAccepted: 'Offer accepted by {candidateName} on {date}',
    offerRejected: 'Offer rejected by {candidateName} on {date}',
    offerSentOn: 'Offer sent on',
    offerAcceptedOn: 'Offer accepted on',
    offerRejectedOn: 'Offer rejected on'
  },
  addAtleastOneSearchTerm: 'Add at least one search term',
  lastActive: 'Last active {text}'
}

export const CandidateListArabic = {
  title: 'المرشحون',
  published: 'تم النشر',
  closed: 'مغلق',
  activeCount: 'نشط',
  archiveCount: 'تم الأرشفة',
  showAllJobs: 'عرض جميع الوظائف',
  addNewCandidate: 'أضف مرشح جديد',
  startTypingNameOrEmailOrTag: 'ابدأ بكتابة الاسم أو البريد الإلكتروني  (الحد الأدنى {length} أحرف)',
  countOfSelected: '{count} من {total} محددين',
  selectAll: 'اختر الكل {count}',
  changeOriginAndSource: 'تغيير المصدر والأصل | تغيير المصدر والأصل ({count})',
  addTags: 'أضف العلامات | اضف اشارة ({count})',
  removeTags: 'إزالة العلامات | إزالة العلامات ({count})',
  insteadAddTags: 'بدلا من ذلك أضف العلامات',
  insteadRemoveTags: 'بدلا من ذلك قم بإزالة العلامات',
  frequentUsedTags: 'العلامات المستخدمة بشكل متكرر',
  progressToNextStage: 'انتقل إلى المرحلة التالية',
  deleteApplication: 'حذف طلب توظيف واحد | حذف {count} طلب',
  deleteApplicationNote: 'سيحذف هذا الإجراء طلبات توظيف المرشحين المحددين، ولا يمكن التراجع عنه. هل تريد المتابعة؟',
  triggerEmailNote: 'يؤدي هذا الإجراء إلى إرسال ايميلات رفض للمرشحين إذا تم تفعيل إرسال ايميلات الرفض التلقائية.',
  archiveApplication: 'أرشفة طلب توظيف المرشح ({count})',
  progressStage: 'تقدم المرحلة ({count})؟',
  progressStageConfirmation: 'سينقل هذا الإجراء المرشح المحدد إلى مرحلة {name}. هل تريد المتابعة؟',
  progressStageNote: 'ملاحظة: يؤدي هذا الإجراء إلى إرسال ايميلات ترحيب بالمرشح في المرحلة الجديدة من عملية التوظيف، إذا تم تفعيل إرسال ايميلات الترحيب التلقائية.',
  selectedOutOf: '{selected} طلب/طلبات توظيف من أصل {total} طلب توظيف في نفس المرحلة',
  progressLoading: 'تقدم المرحلة ({count}) | يتم التقدم في المرحلة...',
  sendEmailTo: 'أرسل ايميل ({to})',
  archivingAs: 'الأرشفة بسبب {reason} ({count})',
  mailsAddedOutOf: '{count} طلب/طلبات توظيف من أصل {total} طلب توظيف لا تحتوي على ايميل في ملفهم الشخصي',
  clearSelection: 'مسح التحديد',
  noCandidatesFound: 'لم يتم العثور على مرشحين 🙂',
  noCandidatesFoundTitle: 'لم يتم العثور على مرشحين',
  noCandidatesFoundDescription: 'جميع المرشحين الذين تقدموا بطلب وتم إضافتهم سيظهرون هنا!',
  owners: 'المالكين',
  placeHolders: {
    searchByJobTitle: 'البحث بالمسمى الوظيفي',
    status: 'الحالة | الحالة({count})',
    archivalReasons: 'سبب الأرشفة | سبب الأرشفة({count})',
    origin: 'المصدر | المصدر({count})',
    source: 'المورد | المورد({count})',
    owners: 'المالكين | المالكين ({count})',
    tag: 'كلمة مميزة | كلمة مميزة({count})',
    tagInput: 'أدخل علامات جديدة أو موجودة',
    tagRemoveInput: 'حدد العلامات الموجودة',
    selectOrigin: 'اختر المصدر',
    enterSource: 'اختر المورد',
    selectArchivalReason: 'اختر سبب الأرشفة'
  },
  filters: {
    origin: 'المصدر',
    source: 'المورد',
    statuses: 'الحالات'
  },
  table: {
    header: {
      name: 'الاسم',
      owner: '',
      jobTitle: 'المسمى الوظيفي',
      stage: 'المرحلة',
      activity: 'آخر نشاط | سبب الأرشفة',
      lastActivity: 'آخر نشاط'
    }
  },
  warnings: {
    bulkArchivalPrefix: 'أرشفة مجمعة باستخدام معرف الوظيفة {part}',
    bulkProgressPrefix: 'انتقال مجمع للمرحلة التالية باستخدام معرف الوظيفة {part}',
    bulkEmailsPrefix: 'إرسال مجموعة ايميلات تلقائيًا باستخدام معرف الوظيفة {part}',
    bulkTags: '{part} العلامات المجمعة مع معرف الوظيفة',
    mailsAddedOutOf: 'تمت إضافة البريد الإلكتروني لدى {count} طلبًا من أصل {total} والذين لا يحتوون على عناوين بريد إلكتروني في ملفاتهم الشخصية',
    sendEmailTo: 'لا يحتوون على عناوين بريد إلكتروني في ملفاتهم الشخصية',
    bulkQueued: '{prefix} في قائمة الانتظار. سنخطرك بعد الإتمام'
  },
  errors: {
    pleaseInputSource: 'رجاءً ادخل المورد',
    pleaseInputOrigin: 'رجاءً ادخل المصدر',
    pleaseInputTags: 'الرجاء إدخال علامات جديدة أو موجودة',
    pleaseInputArchiveReason: 'رجاءً اختر سبب الأرشفة'
  },
  successMessages: {
    sourceOriginChanged: 'تم تغيير المصدر والمورد بنجاح',
    applicationDeleted: 'تم حذف طلبات التوظيف بنجاح',
    addTags: 'تمت إضافة العلامات بنجاح',
    removeTags: 'تمت إزالة العلامات بنجاح',
    archivedApplication: 'تم أرشفة طلبات التوظيف بنجاح',
    progressed: 'تم نقل المرشحين إلى المرحلة التالية بنجاح',
    emailsSent: 'تم إرسال الايميلات بنجاح',
    successMessages: 'تم إضافة الملفات بنجاح',
    filesAdded: 'تمت إضافة الملفات بنجاح'
  },
  cvSearchDialog: {
    title: 'ابحث في قاعدة بيانات سيرتك الذاتية',
    subTitle: 'ابحث عن السير الذاتية التي',
    addSearchTerm: 'إضافة مصطلح بحث',
    matchingCandidates: 'المرشحون المطابقون: ',
    filters: {
      includesAll: 'يشمل جميع',
      includesAny: 'يشمل أي من'
    }
  },
  cvSearch: 'بحث السيرة الذاتية',
  latestActivity: {
    offerSent: 'تم إرسال العرض من قبل {recruiterName} في {date}',
    offerAccepted: 'تم قبول العرض من قبل {candidateName} في {date}',
    offerRejected: 'تم رفض العرض من قبل {candidateName} في {date}',
    offerSentOn: 'تم إرسال العرض في',
    offerAcceptedOn: 'تم قبول العرض في',
    offerRejectedOn: 'تم رفض العرض في'
  },
  addAtleastOneSearchTerm: 'أضف مصطلح بحث واحد على الأقل',
  lastActive: 'آخر نشاط منذ {count} أيام'
}
