type DateFormatOption =
  | 'YYYY-MM-DD' // 2021-01-01
  | 'MMM DD, YYYY h:mm A' // Jan 01, 2021 12:00 PM
  | 'MMM D, YY [at] h:mm A' // Jan 1, 21 at 12:00 PM
  | 'MMM DD, YY' // Jan 01, 21
  | 'YYYY-MM-DD HH:mm' // 2021-01-01 12:00
  | 'MMMM DD, YYYY' // January 01, 2021
  | 'MMMM DD, YY' // January 01, 21
  | 'MMM DD, YYYY' // Jan 01, 2021
  | 'DD-MMM-YYYY' // 01-Jan-2021
  | 'DD-MM-YYYY' // 01-01-2021
  | 'MMM DD, YY [at] h:mm a' // Jan 01, 21 at 12:00 pm
  | 'MMMM DD, HH:mm:ss' // January 01, 12:00:00
  | 'dddd, MMMM D' // Monday, January 1
  | 'h:mm A' // 12:00 PM
  | 'HH:mm'; // 12:00

export interface DateTimeDurationDetails {
  years: number
  months: number
  days: number
  hours: number
  minutes: number
  seconds: number
}
function isValidDate(d: Date): boolean {
  return !isNaN(d.getTime())
}

function safeDateParsing(value: string | Date): Date | null {
  const parsedDate = new Date(value)
  return isValidDate(parsedDate) ? parsedDate : null
}

export const getUserTimezone = function(): string {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export const formatTime = function(hours: number, units: string): string {
  if (isNaN(hours)) return ''
  const days = Math.round(hours / 24)

  switch (units) {
    case 'hours':
      return `${hours}`
    case 'days':
      return `${days}`
    default:
      return ''
  }
}

/*
example output
{
  years: 2,
  months: 3,
  days: 24,
  hours: 2,
  minutes: 6,
  seconds: 46
}
*/
const getDateTimeDetails = (startDate: string | Date, endDate: string | Date): DateTimeDurationDetails => {
  const start = new Date(startDate)
  const end = new Date(endDate)
  let years = end.getFullYear() - start.getFullYear()
  let months = end.getMonth() - start.getMonth()
  let days = end.getDate() - start.getDate()
  let hours = end.getHours() - start.getHours()
  let minutes = end.getMinutes() - start.getMinutes()
  let seconds = end.getSeconds() - start.getSeconds()
  // Adjust for negative values
  if (seconds < 0) {
    seconds += 60
    minutes -= 1
  }
  if (minutes < 0) {
    minutes += 60
    hours -= 1
  }
  if (hours < 0) {
    hours += 24
    days -= 1
  }
  if (days < 0) {
    // Get the number of days in the previous month
    const previousMonth = new Date(end.getFullYear(), end.getMonth(), 0).getDate()
    days += previousMonth
    months -= 1
  }
  if (months < 0) {
    months += 12
    years -= 1
  }
  return {
    years,
    months,
    days,
    hours,
    minutes,
    seconds
  }
}

/*
example out put
5 minutes ago
2 days ago
yerterday
2 year back
3 months ago
*/
export const getTimeDifferenceText = (oldDate: string | Date, newDate: string | Date): string => {
  const difference = getDateTimeDetails(oldDate, newDate)
  const { years, months, days, hours, minutes, seconds } = difference
  if (years > 0) {
    return `${years} year${years > 1 ? 's' : ''} back`
  } else if (months > 0) {
    return `${months} month${months > 1 ? 's' : ''} back`
  } else if (days > 0) {
    return days > 1 ? `${days} days ago` : 'yesterday'
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''} ago`
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`
  } else {
    return `${seconds} second${seconds > 1 ? 's' : ''} ago`
  }
}

export const formatDate = function(value: string | Date, format: DateFormatOption): string {
  const date = safeDateParsing(value)
  if (!date) return ''

  // Direct formatting for custom patterns
  const directFormatted = formatDirectly(date, format)
  if (directFormatted) return directFormatted

  let options: Intl.DateTimeFormatOptions
  switch (format) {
    case 'MMM DD, YYYY h:mm A':
      options = { year: 'numeric', month: 'short', day: '2-digit', hour: 'numeric', minute: '2-digit', hour12: true }
      break
    case 'MMM D, YY [at] h:mm A':
      options = { year: '2-digit', month: 'short', day: 'numeric', hour: 'numeric', minute: '2-digit', hour12: true }
      break
    case 'MMM DD, YY':
      options = { year: '2-digit', month: 'short', day: '2-digit' }
      break
    case 'MMMM DD, YYYY':
      options = { year: 'numeric', month: 'long', day: '2-digit' }
      break
    case 'MMMM DD, YY':
      options = { year: '2-digit', month: 'long', day: '2-digit' }
      break
    case 'MMM DD, YYYY':
      options = { year: 'numeric', month: 'short', day: '2-digit' }
      break
    case 'MMMM DD, HH:mm:ss':
      options = { month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }
      break
    case 'h:mm A':
      options = { hour: 'numeric', minute: '2-digit', hour12: true }
      break
    case 'HH:mm':
      options = { hour: '2-digit', minute: '2-digit', hour12: false }
      break
    default:
      return ''
  }

  return new Intl.DateTimeFormat('en-US', options).format(date)
}

const formatDirectly = function(date: Date, format: DateFormatOption): string {
  switch (format) {
    case 'YYYY-MM-DD':
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
    case 'YYYY-MM-DD HH:mm':
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`
    case 'DD-MM-YYYY':
      return `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`
    case 'MMM DD, YY [at] h:mm a':
      return `${date.toLocaleDateString('en-US', { year: '2-digit', month: 'short', day: 'numeric' })} at ${date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true })}`
    case 'dddd, MMMM D':
      return `${date.toLocaleDateString('en-US', { weekday: 'long' })}, ${date.toLocaleDateString('en-US', { month: 'long', day: 'numeric' })}`
    case 'DD-MMM-YYYY':
      return `${String(date.getDate()).padStart(2, '0')}-${date.toLocaleDateString('en-US', { month: 'short' })}-${date.getFullYear()}`
    default:
      return ''
  }
}

export const utcToLocal = function(value: string | Date): string {
  const date = safeDateParsing(value)
  if (!date) return ''

  return date.toLocaleString()
}

export const timeUntil = (date: Date | string): string => {
  const now = new Date()
  const futureDate = new Date(date)
  let delta = Math.abs(futureDate.getTime() - now.getTime()) / 1000

  // calculate (and subtract) whole days
  const days = Math.floor(delta / 86400)
  delta -= days * 86400

  // calculate (and subtract) whole hours
  const hours = Math.floor(delta / 3600) % 24
  delta -= hours * 3600

  // calculate (and subtract) whole minutes
  const minutes = Math.floor(delta / 60) % 60
  delta -= minutes * 60

  // what's left is seconds
  const seconds = delta % 60 // in theory the modulus is not necessary

  const result: string[] = []
  if (days > 0) result.push(`${days} day${days > 1 ? 's' : ''}`)
  if (hours > 0) result.push(`${hours} hour${hours > 1 ? 's' : ''}`)
  if (minutes > 0) result.push(`${minutes} minute${minutes > 1 ? 's' : ''}`)
  if (seconds > 0) result.push(`${Math.floor(seconds)} second${seconds > 1 ? 's' : ''}`)

  return result.length > 0 ? result.join(' ') : '0 seconds'
}
