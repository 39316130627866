export const RecruiterNavigationArabic = {
  jobs: 'الوظائف',
  candidates: 'المرشحون',
  interviews: 'مقابلات التوظيف',
  referrals: 'الترشيحات والتزكية',
  employeePortal: 'بوابة الموظف',
  reports: 'Reports (Beta)',
  tooltipContent: {
    downloadChromeExtension: 'تحميل ملحق Chrome',
    helpCenter: 'مركز المساعدة',
    settings: 'الإعدادات',
    changeLanguageToEng: 'تغيير اللغة إلى الإنجليزية',
    changeLanguageToAe: 'تغيير اللغة إلى العربية'
  }
}

export const RecruiterNavigationEnglish = {
  jobs: 'Jobs',
  candidates: 'Candidates',
  interviews: 'Interviews',
  referrals: 'Referrals',
  employeePortal: 'Employee portal',
  reports: 'Reports (Beta)',
  tooltipContent: {
    downloadChromeExtension: 'Download Chrome extension',
    helpCenter: 'Help center',
    settings: 'Settings',
    changeLanguageToEng: 'Change language to English',
    changeLanguageToAe: 'Change language to Arabic'
  }
}
