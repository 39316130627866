import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import DynamicConfigsResource, { AppConfigEnum, IDynamicConfigs } from '@/api/dynamicConfigs'
import store from '@/store'
import {
  LinkedinIntegrationType
} from '@/views/settings/integrations/job-boards/linked-in-job-board/models/linkedin-integration-type'
import {
  LinkedinIntegration
} from '@/views/settings/integrations/job-boards/linked-in-job-board/models/linkedin-integration'
import {
  CompanyThirdPartyIntegrationsItem,
  CompanyThirdPartyIntegrationsTypeEnum
} from '@/views/settings/integrations/hrms-integration/models/CompanyThirdPartyIntegrationsItem'

@Module({ dynamic: true, name: 'company', store, namespaced: true })
class Company extends VuexModule {
  public haveLanguageSupport = false
  public companyLogo = ''
  public haveReportsBetaTesting = false
  public linkedinIntegration: LinkedinIntegration = {
    linkedinCompanyId: null,
    isLinkedinIntegrationActive: true,
    linkedinIntegrationType: LinkedinIntegrationType.NONE
  }

  public hrmsIntegrationType = '';
  public hrmsIntegrations: CompanyThirdPartyIntegrationsItem[] = []

  @Mutation
  public SET_LANGUAGE_SUPPORT(support: boolean) {
    this.haveLanguageSupport = support
  }

  @Mutation
  public SET_REPORTS_BETA_TESTING(support: boolean) {
    this.haveReportsBetaTesting = support
  }

  @Mutation
  private SET_LOGO(logo: string) {
    this.companyLogo = logo
  }

  @Mutation
  public SET_UP_LINKEDIN_INTEGRATION(linkedinIntegration: LinkedinIntegration) {
    this.linkedinIntegration = linkedinIntegration
  }

  @Mutation
  public SET_HRMS_INTEGRATION_TYPE(type: CompanyThirdPartyIntegrationsTypeEnum) {
    this.hrmsIntegrationType = type
  }

  @Mutation
  public SET_HRMS_INTEGRATIONS(type: CompanyThirdPartyIntegrationsItem[]) {
    this.hrmsIntegrations = type
  }

  @Action
  public setHrmsIntegrationType(type: CompanyThirdPartyIntegrationsTypeEnum) {
    this.SET_HRMS_INTEGRATION_TYPE(type)
  }

  @Action
  public UpdateCompanyLogo(logo: string) {
    this.SET_LOGO(logo)
  }

  @Action
  private async getDynamicConfig(companyId: string): Promise<IDynamicConfigs[]> {
    try {
      const dynamicAppConfigs = new DynamicConfigsResource()
      const queryString = new URLSearchParams({
        companyId
      }).toString()

      const response = await dynamicAppConfigs.get(`?${queryString}`)
      return response.data as IDynamicConfigs[]
    } catch (e) {
      return []
    }
  }

  @Action
  public async setDynamicConfigurations(companyId: string) {
    const dynamicConfigs = await this.getDynamicConfig(companyId)
    const haveLanguageSupport = dynamicConfigs.find((each) => each.name === AppConfigEnum.LANGUAGE_SUPPORT)?.value === 'true'
    this.SET_LANGUAGE_SUPPORT(haveLanguageSupport)
  }

  @Action
  public async setUpLinkedinIntegration(linkedinIntegration: LinkedinIntegration) {
    this.context.commit('SET_UP_LINKEDIN_INTEGRATION', linkedinIntegration)
  }

  @Action
  public async setReportsBetaTesting(companyId: string) {
    const haveSupport = await this.context.dispatch('getDynamicConfig', {
      companyId,
      configName: AppConfigEnum.REPORTS
    })
    this.context.commit('SET_REPORTS_BETA_TESTING', haveSupport)
  }

  @Action
  public async setHrmsIntegrations(integrations: CompanyThirdPartyIntegrationsItem[]) {
    this.SET_HRMS_INTEGRATIONS(integrations)
  }

  @Action
  public async getHrmsIntegrations() {
    return this.hrmsIntegrations
  }
}

export const CompanyModule = getModule(Company)
