import { RouteConfig } from 'vue-router'
import PluginLayout from '@/views/plugin/index.vue'
import { RolesEnum } from '@/views/recruiter/models/roles-model'
import DashboardLayout from '@/layout/DashboardLayout.vue'
import Layout from '@/layout/index.vue'
import RecruiterSettingsDashboard from '@/layout/RecruiterSettingsDashboard.vue'
import { RecruiterSettingsRoutes } from '@/routes/recruiter-settings.routes'
import {
  CAREER_PAGE_PERMISSIONS,
  COMPANY_AGENCY_MANAGE_PERMISSIONS,
  COMPANY_BOOKING_LINKS_MANAGE,
  COMPANY_INTERVIEW_FEEDBACK_TEMPLATE_MANAGE,
  COMPANY_OFFER_LETTER_TEMPLATE_MANAGE
} from '@/permissions/permissions-constants'
import { PermissionsEnum } from '@/permissions/permissions-enum'

export const RECRUITER_ROUTES: RouteConfig[] = [
  {
    path: '/plugin',
    component: PluginLayout,
    meta: {
      hidden: true
    },
    children: [
      {
        path: 'default',
        component: () => import(/* webpackChunkName: "pluginDefault" */ '@/views/plugin/candidate-search.vue'),
        meta: {
          hidden: true,
          title: 'Search Candidate'
        }
      },
      {
        path: 'candidate-profile/:applicationId',
        component: () => import(/* webpackChunkName: "pluginApplication" */ '@/views/candidate-timeline/CandidateTimeline.vue'),
        meta: {
          hidden: true,
          title: 'Candidate profile plugin'
        }
      }
    ]
  },
  {
    path: '/recruiter',
    component: DashboardLayout,
    redirect: '/recruiter/jobs',
    meta: { hidden: true },
    children: [
      {
        path: 'jobs',
        component: () => import(/* webpackChunkName: "recruiterHome" */ '@/views/jobs-list/jobs.vue'),
        meta: {
          hidden: true,
          title: 'Jobs',
          icon: 'dashboard',
          roles: [RolesEnum.COMPANY_ADMIN, RolesEnum.COMPANY_INTERVIEWER]
        }
      },
      {
        path: 'candidates',
        component: () => import(/* webpackChunkName: "candidateList" */ '@/views/candidate-list/CandidateList.vue'),
        meta: {
          hidden: true,
          title: 'Candidate List',
          icon: 'dashboard',
          roles: [RolesEnum.COMPANY_ADMIN, RolesEnum.COMPANY_INTERVIEWER]
        }
      },
      {
        path: 'candidates/add-candidate',
        component: () => import(/* webpackChunkName: "candidateListAdd" */ '@/views/candidate-list/add-candidate.vue'),
        meta: {
          hidden: true,
          title: 'Add Candidate',
          icon: 'dashboard',
          roles: [RolesEnum.COMPANY_ADMIN, RolesEnum.COMPANY_INTERVIEWER]
        }
      },
      {
        path: 'employee-portal',
        component: () => import(/* webpackChunkName: "employeeReferrals" */ '@/views/referrals/Referrals.vue'),
        meta: {
          hidden: true,
          title: 'Employee Portal',
          icon: 'dashboard',
          roles: [RolesEnum.COMPANY_ADMIN, RolesEnum.COMPANY_INTERVIEWER, RolesEnum.COMPANY_EMPLOYEE]
        }
      },
      {
        path: 'home',
        component: () => import(/* webpackChunkName: "interviews" */ '@/views/home-page/index.vue'),
        meta: {
          hidden: true,
          title: 'Home',
          icon: 'dashboard',
          roles: [RolesEnum.COMPANY_ADMIN, RolesEnum.COMPANY_INTERVIEWER]
        }
      },
      {
        path: 'reports',
        component: () => import(/* webpackChunkName: "reports" */ '@/views/recruiter/reports/Reports.vue'),
        meta: {
          hidden: true,
          title: 'Reports',
          icon: 'dashboard',
          permissions: [PermissionsEnum.COMPANY_SUPERSET_REPORTS]
        }
      },
      {
        path: 'interview-scheduling',
        component: () => import(/* webpackChunkName: "interviewSchedule" */ '@/views/interview-scheduling/InterviewScheduling.vue'),
        meta: {
          hidden: true,
          title: 'Interview Scheduling',
          icon: 'dashboard',
          roles: [RolesEnum.COMPANY_ADMIN, RolesEnum.COMPANY_INTERVIEWER]
        }
      },
      {
        path: 'jobs/job-role/:jobRoleId/:editTab/:mode',
        component: () => import(/* webpackChunkName: "recruiterJobRoleEdit" */ '@/views/recruiter/job-role/JobRole.vue'),
        meta: {
          hidden: true,
          title: 'Job',
          icon: 'dashboard',
          roles: [RolesEnum.COMPANY_ADMIN, RolesEnum.COMPANY_INTERVIEWER]
        }
      },
      {
        path: 'jobs/job-role/:jobRoleId/:stageId/:moduleId/:moduleName/edit',
        component: () => import(/* webpackChunkName: "stageEdit" */ '@/views/recruiter/stages/EditModule.vue'),
        meta: {
          hidden: true,
          title: 'Edit',
          icon: 'dashboard',
          roles: [RolesEnum.COMPANY_ADMIN, RolesEnum.COMPANY_INTERVIEWER]
        }
      },
      {
        path: 'booking-link',
        component: () => import(/* webpackChunkName: "bookingLink" */ '@/views/recruiter/booking-links/BookingLink.vue'),
        meta: {
          hidden: true,
          title: 'Booking Link page',
          icon: 'dashboard',
          permissions: [...COMPANY_BOOKING_LINKS_MANAGE]
        }
      },
      {
        path: 'candidate-profile/:applicationId',
        component: () => import(/* webpackChunkName: "candidateTimeline" */ '@/views/candidate-timeline/CandidateTimeline.vue'),
        meta: {
          title: 'Candidate Profile',
          hidden: true,
          roles: [RolesEnum.COMPANY_ADMIN, RolesEnum.COMPANY_INTERVIEWER]
        },
        name: 'candidate-profile',
        props: {
          header: true,
          content: true
        }
      },
      {
        path: 'agencies/:agencyId',
        component: () => import(/* webpackChunkName: "addOrEditAgency" */ '@/views/agencies/AddOrEditAgency.vue'),
        meta: {
          title: 'Agencies',
          hidden: true,
          permissions: [...COMPANY_AGENCY_MANAGE_PERMISSIONS]
        },
        name: 'agencies',
        props: {
          header: true,
          content: true
        }
      },
      {
        path: 'offers',
        component: Layout,
        redirect: '/recruiter-settings/offers',
        meta: { hidden: true },
        children: [
          {
            path: 'send/:applicationId/job/:jobRoleId',
            component: () => import(/* webpackChunkName: "offersHome" */ '@/views/offers/SendOffer.vue'),
            meta: {
              hidden: true,
              title: 'Send offer',
              roles: [RolesEnum.COMPANY_ADMIN, RolesEnum.COMPANY_INTERVIEWER]
            }
          },
          {
            path: ':templateId',
            component: () => import(/* webpackChunkName: "offersTemplate" */ '@/views/offers/CreateEditOffers.vue'),
            meta: {
              hidden: true,
              title: 'Offer template',
              permissions: [...COMPANY_OFFER_LETTER_TEMPLATE_MANAGE]
            }
          }
        ]
      },
      {
        path: 'interview-scorecard-templates',
        component: Layout,
        redirect: '/recruiter-settings/interview-scorecard-templates',
        meta: { hidden: true },
        children: [
          {
            path: ':templateId',
            component: () => import(/* webpackChunkName: "feedBackTemplate" */ '@/views/recruiter/manage-interview-feedback-templates/CreateEditInterviewFeedbackTemplate.vue'),
            meta: {
              hidden: true,
              title: 'Scorecard template',
              permissions: [...COMPANY_INTERVIEW_FEEDBACK_TEMPLATE_MANAGE]
            }
          },
          {
            path: 'add-score/application/:applicationId/booking/:bookingId/',
            component: () => import(/* webpackChunkName: "addFeedbackScore" */ '@/views/recruiter/manage-interview-feedback-templates/components/add-score-components/AddFeedbackScoreTemplate.vue'),
            meta: {
              hidden: true,
              title: 'Add Score',
              permissions: [PermissionsEnum.COMPANY_VIEW_AND_SUBMIT_SCORES]
            }
          }
        ]
      }
    ]
  },
  {
    path: '/recruiter-settings',
    component: RecruiterSettingsDashboard,
    redirect: '/recruiter',
    children: RecruiterSettingsRoutes
  },
  {
    path: '/integration/googlecalendar/callback',
    component: () => import(/* webpackChunkName: "integrationGoogleCalendar" */ '@/views/integrations/callbacks/google-integration-callback.vue'),
    meta: {
      hidden: true,
      title: 'Google calendar integration callback',
      icon: 'dashboard',
      roles: [RolesEnum.COMPANY_ADMIN, RolesEnum.COMPANY_INTERVIEWER]
    }
  },
  {
    path: '/integration/outlook/calendar/callback',
    component: () => import(/* webpackChunkName: "integrationOutlookCalendar" */ '@/views/integrations/callbacks/outlook-calendar-integration-callback.vue'),
    meta: {
      hidden: true,
      title: 'Outlook calendar integration callback',
      icon: 'dashboard',
      roles: [RolesEnum.COMPANY_ADMIN, RolesEnum.COMPANY_INTERVIEWER]
    }
  },
  {
    path: '/integration/outlook/mail/callback',
    component: () => import(/* webpackChunkName: "integrationOutlookMain" */ '@/views/integrations/callbacks/outlook-mail-callback.vue'),
    meta: {
      hidden: true,
      title: 'Outlook mail callback',
      icon: 'dashboard',
      roles: [RolesEnum.COMPANY_ADMIN, RolesEnum.COMPANY_INTERVIEWER]
    }
  },
  {
    path: '/view-question/:questionId',
    component: () => import(/* webpackChunkName: "recruiterQuestionsView" */ '@/views/recruiter/question-view.vue'),
    meta: {
      hidden: true,
      title: 'View Question',
      icon: 'dashboard',
      roles: [RolesEnum.COMPANY_ADMIN, RolesEnum.COMPANY_INTERVIEWER]
    }
  },
  {
    path: '/careers',
    component: Layout,
    redirect: '/recruiter',
    meta: { hidden: true },
    children: [
      {
        path: ':companyName/edit',
        component: () => import(/* webpackChunkName: "careerEdit" */ '@/views/careers/edit/CareersEdit.vue'),
        meta: {
          hidden: true,
          title: 'Careers',
          icon: 'dashboard',
          permissions: [...CAREER_PAGE_PERMISSIONS],
          requiredAllPermissions: true
        }
      }
    ]
  }
]
