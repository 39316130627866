
import { Component, Vue, Watch } from 'vue-property-decorator'
import { UserModule } from '@/store/modules/user'
import { LanguageEnum, LanguageModule } from '@/store/modules/language'
import { getLanguage, setLanguage } from '@/utils/cookies'
import WcIconQuestionMark from '@/components/UILibrary/Icons/WcIconQuestionMark.vue'
import ChatBotDialog from '@/components/Chatbot/ChatBotDialog.vue'
import Banner from '@/components/UILibrary/Banner.vue'
import { timeUntil } from '@/utils/date'
import { ElNotificationComponent } from 'element-ui/types/notification'
import { isMobile, isPlugin } from '@/utils/general'
import { setSmartlookUser } from '@/smartlook'
import { isProduction } from '@/utils/constants'
import { isCurrentPageIsPubic } from '@/utils/request'
import Button from '@/components/UILibrary/Buttons/Button.vue'
import { getFromExtension } from '@/utils/extension-storage'
import { AppModule } from '@/store/modules/app'
import OutDatedApp from '@/components/UILibrary/OutDatedApp.vue'
import { sendErrorsToClarity } from '@/utils/clarity-custom-events'

@Component({
  name: 'App',
  components: { OutDatedApp, Button, ChatBotDialog, WcIconQuestionMark, Banner },
  methods: {
    isMobile,
    UserModule() {
      return UserModule
    }
  },
  computed: {
    getCurrentLanguage() {
      return LanguageModule.language
    }
  }
})
export default class extends Vue {
  // refresh variables
  refreshing = false
  registration = null as ServiceWorkerRegistration | null
  timer: number | null = null;
  timeRemaining = ''
  activeNotification = null as ElNotificationComponent | null
  isVisible = false
  isNewVersionAvailable = false
  shouldUpdateBannerVisible = false
  isMobileScreen = isMobile();

  get isImpersonatedSession() {
    return UserModule.impersonatedMode
  }

  get loggedInUserEmail(): string {
    return UserModule.email
  }

  get isOutDatedApp(): boolean {
    return AppModule.isOutDated
  }

  @Watch('loggedInUserEmail')
  setSmartlookSession(): void {
    const email = this.loggedInUserEmail
    if (email && isProduction) {
      const userId = UserModule.id
      const companyId = UserModule.companyId
      const fullName = UserModule.name
      setSmartlookUser(email, {
        userId,
        companyId,
        fullName
      })
    }
  }

  @Watch('$route')
  onRouteChange(): void {
    this.checkIfPublicPage()
  }

  async checkIfPublicPage(): Promise<void> {
    if (isCurrentPageIsPubic()) { // for public pages we don't show this update
      await this.refreshApp()
    } else {
      this.shouldUpdateBannerVisible = this.isNewVersionAvailable
    }
  }

  @Watch('isImpersonatedSession')
  startTimer(value: boolean) {
    if (value) {
      this.timer = window.setInterval(() => {
        const sessionEndsIn = UserModule.sessionEndsIn
        if (sessionEndsIn) {
          this.timeRemaining = `Session ends in: ${timeUntil(sessionEndsIn)}`
        }
        if (sessionEndsIn && new Date(sessionEndsIn) < new Date()) {
          UserModule.LogOut()
          window.location.reload()
        }
      }, 1000)
    }
  }

  @Watch('getCurrentLanguage')
  onLanguageChanged(newLanguage: string, oldLanguage: string) {
    if (newLanguage !== oldLanguage) {
      const direction = newLanguage === LanguageEnum.ENGLISH ? 'ltr' : 'rtl'
      document.body.dir = direction
      document.dir = direction
      document.documentElement.lang = newLanguage
      this.$i18n.locale = newLanguage
      setLanguage(newLanguage)
    }
  }

  initializeScript(sessionToken: string) {
    const appId = process.env.VUE_APP_DEV_REV_ID
    // Remove old script elements if they exist
    const oldScripts = document.querySelectorAll('.dev-rev-plug-app')
    oldScripts.forEach(script => script.remove())

    // Create and append new script elements
    const plugScript = document.createElement('script')
    plugScript.setAttribute('src', 'https://plug-platform.devrev.ai/static/plug.js')
    plugScript.setAttribute('type', 'text/javascript')
    plugScript.classList.add('dev-rev-plug-app')
    document.body.appendChild(plugScript)

    plugScript.onload = () => {
      const initScript = document.createElement('script')
      initScript.type = 'text/javascript'
      initScript.classList.add('dev-rev-plug-app')
      initScript.text = `
          window.plugSDK.init({
            app_id: '${appId}',
            session_token: '${sessionToken}',
          });
        `
      document.body.appendChild(initScript)
    }
  }

  mounted(): void {
    const chosenLanguage = getLanguage() as LanguageEnum
    if (chosenLanguage) {
      LanguageModule.setLanguage(chosenLanguage)
    }
    this.isMobileScreen = isMobile()
    this.listenAppUpdates()
    this.startTimer(this.isImpersonatedSession)
    getFromExtension('token')
    getFromExtension('refreshToken')
    window.addEventListener('message', (event) => {
      if (isPlugin()) {
        if (event.data.type === 'response' && event.data.key === 'token') {
          if (UserModule.token === '') {
            UserModule.SetTokenForCandidate(event.data.value || '')
          }
        }
        if (event.data.type === 'response' && event.data.key === 'refreshToken') {
          if (UserModule.refreshToken === '') {
            UserModule.SetRefreshToken(event.data.value || '')
          }
        }
      }
    })
    window.onerror = function(message, source, lineno, colno, error) {
      // Ensure message is a string
      const errorMessage = typeof message === 'string' ? message : message.toString()

      // sendErrorsToClarity -  to send the error details to Clarity
      sendErrorsToClarity('GlobalJsError', {
        message: errorMessage,
        source: source || 'Unknown source',
        lineno: lineno || 'Unknown line',
        colno: colno || 'Unknown column',
        stack: error ? error.stack : 'No stack trace',
        errorDetails: JSON.stringify(error)
      })

      // Continue with the default browser error handling
      return false
    }
  }

  async updateAvailable(event: CustomEvent): Promise<void> {
    this.registration = event.detail
    this.isNewVersionAvailable = true
    await this.checkIfPublicPage()
  }

  // Called when the user accepts the update
  refreshApp(): void {
    if (this.activeNotification) {
      this.activeNotification.close()
    }

    // Make sure we only send a 'skip waiting' message if the SW is waiting
    if (!this.registration || !this.registration.waiting) {
      return
    }
    // send message to SW to skip the waiting and activate the new SW
    this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    this.isNewVersionAvailable = false
  }

  private async hardRefresh(): Promise<void> {
    // Perform a hard refresh by navigating to the current URL
    await AppModule.SetIsOutDatedApp(false)
    this.skipWaitingForWaitingUpdates()
    window.location.href = window.location.origin + window.location.pathname
  }

  skipWaitingForWaitingUpdates() {
    navigator.serviceWorker.getRegistration().then(reg => {
      const swReq = reg as ServiceWorkerRegistration
      if (swReq.waiting) {
        swReq.waiting.postMessage({ type: 'SKIP_WAITING' })
      }
    })
  }

  listenAppUpdates() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    document.addEventListener('swUpdated', this.updateAvailable)

    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      // Here the actual reload of the page occurs
      window.location.reload()
    })

    // Check if there's a waiting service worker on load
    navigator.serviceWorker.getRegistration().then((registration) => {
      if (registration && registration.waiting) {
        if (!this.isNewVersionAvailable) {
          this.isNewVersionAvailable = true
        }
        this.registration = registration
      }
    })

    window.addEventListener('beforeunload', () => {
      const pageAccessedByReload = (
        (window.performance.navigation && window.performance.navigation.type === 1) ||
        window.performance
          .getEntriesByType('navigation')
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .map((nav) => nav.type)
          .includes('reload')
      )
      if (pageAccessedByReload) {
        this.skipWaitingForWaitingUpdates()
      }
    })
  }

  beforeDestroy() {
    // Clear the timer when the component is destroyed
    if (this.timer) {
      window.clearInterval(this.timer)
    }
  }
}
