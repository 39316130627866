export const CandidateTimelineEnglish = {
  goBackToCandidates: 'Go back to candidates',
  recruiterActions: {
    resendInvite: 'Resend invite',
    editApplication: 'Edit application',
    moveToJob: 'Move to job',
    deleteApplication: 'Delete application',
    unArchive: 'Unarchive',
    resendEmail: {
      sendInvitationAgain: 'Are you sure you want to send the invitation again?',
      invitationEmailNotAdded: 'There is no invitation email added for this stage. Please edit the job to add an invitation email.',
      sendInvitation: 'Send invitation'
    },
    editApplicationComp: {
      editCandidate: 'Edit candidate',
      firstNameLabel: 'First name',
      firstNamePlaceholder: 'Enter candidate\'s first name',
      lastNameLabel: 'Last name',
      lastNamePlaceholder: 'Enter candidate\'s last name',
      originLabel: 'Origin',
      originPlaceholder: 'Select origin',
      sourceLabel: 'Source',
      sourcePlaceholder: 'Enter source name'
    },
    addOrMoveToJob: {
      moveCandidateToNewJob: 'Move candidate to a new job',
      addCandidateToNewJob: 'Add candidate to a new job',
      chooseJobLabel: 'Choose job',
      chooseJobPlaceholder: 'Select job',
      selectStageLabel: 'Select stage',
      selectStagePlaceholder: 'Select stage'
    },
    deleteApplicationComp: {
      dialogTitle: 'Are you sure you want to delete the application?',
      deleteApplicationButton: 'Delete application'
    },
    jobNameDropdown: {
      goToCandidateList: 'Go to candidate list',
      openJobDescription: 'Open job description',
      editJob: 'Edit job'
    },
    inviteSendConfirmation: {
      cannotUndo: 'This action cannot be undone. Are you sure?'
    },
    sendRejectionEmail: {
      sendRejectionTitle: 'Send rejection email',
      emailTemplate: 'Email template',
      sendEmailAndReject: 'Send email and reject'
    },
    addEmployeeInBayzat: 'Add as an employee',
    viewEmployeeProfile: 'View employee profile',
    addEmployeeInBayzatDialog: {
      formLabels: {
        firstName: 'First name',
        lastName: 'Last name',
        integrationName: 'Integration name',
        nationality: 'Nationality',
        joiningDate: 'Hiring date',
        phoneContact: 'Mobile no.',
        employeeTitle: 'Employee title',
        personalEmail: 'Personal email',
        department: 'Department',
        workEmail: 'Work email'
      },
      createEmployeeInHRMS: 'Create employee in HRMS',
      employeeAddingInfo: 'This action will add a new employee on the Bayzat platform. You may log in to your Bayzat account and enter additional employee details on the employee profile.',
      emailSendingInfo: 'Adding an employee will send an email to people managers, ATS managers, and super admins on the Bayzat HRMS platform.',
      toKnowMore: 'to know more about the integration.',
      selectNationality: 'Please select nationality',
      selectIntegration: 'Please select integration',
      employeeAddedSuccessfully: 'Employee data sent successfully to HRMS.'
    },
    addEmployeeInBayzatEvent: {
      creatingEmployeeInHRMS: 'Creating employee in HRMS...',
      failedToAdd: 'Failed to add employee to Bayzat HRMS. Please add again.',
      reasonToFail: 'Reason: {reason}',
      addedToHRMS: 'Added to HRMS'
    },
    archiveAs: 'Archiving as {archivalReason}'
  },
  candidateInfo: {
    contactInformation: 'Contact information',
    addInfo: 'Add info',
    primaryEmailCannotDeleteInfo: 'Primary email cannot be deleted',
    socialLinks: 'Social links',
    addLink: 'Add link',
    deleteEmail: 'Delete email',
    deletePhoneNumber: 'Delete phone number',
    deleteLink: 'Delete link',
    updateEmail: 'Update email',
    addEmail: 'Add email',
    updatePhoneNumber: 'Update phone number',
    addPhoneNumber: 'Add phone number',
    updateLink: 'Update link',
    editEmail: 'Edit email',
    editPhoneNumber: 'Edit phone number',
    editLink: 'Edit link',
    emailDeleteConfirmation: 'Are you sure you want to delete this email?',
    phoneNumberDeleteConfirmation: 'Are you sure you want to delete this phone number?',
    linkDeleteConfirmation: 'Are you sure you want to delete this link?',
    phoneNumberPlaceholder: 'Enter candidate\'s phone number',
    emailPlaceholder: 'Enter candidate\'s email',
    linkPlaceholder: 'Enter link',
    mergeApplicationsAndDeleteEmail: 'Merge applications and update email',
    emailError: 'Please enter a valid email',
    linkError: 'Please enter a valid link',
    phoneNumberError: 'Please enter a valid phone number',
    emailIntegrationNeeded: 'Email integration needed!',
    requireIntegratedEmail: 'We need integrated email to sync emails.',
    goToSettings: 'Go to settings',
    mergeApplicationsConfirmation: 'A candidate exists with the email {email} you have entered. Do you want to merge the job applications to one candidate?',
    relatedTo: 'Related to {jobRoleName}',
    copyLink: 'Copy link',
    copyPhoneNumber: 'Copy phone number',
    copyEmail: 'Copy email',
    syncEmails: 'Sync candidate emails',
    contactWhatsApp: 'Contact on WhatsApp'
  },
  addANote: 'Add a note',
  scheduleInterviewTooltip: 'Schedule interview',
  noPermissionToProgress: 'You do not have permissions to progress candidate',
  noPermissionToArchive: 'You do not have permissions to archive candidate',
  sendEmail: 'Send email',
  addFeedback: 'Add feedback',
  sendOfferTooltip: 'Send offer',
  addNotes: {
    placeholder: 'Add a note here e.g., @mention to message a coworker',
    noResult: 'No results found',
    secretNoteInfo: 'Only members with access can view secret notes.',
    addNote: 'Add note',
    addedANote: 'Added a note',
    deleteNoteConfirmation: 'Are you sure you want to delete this note?',
    secretNote: 'Secret note',
    editNote: 'Edit note',
    deleteNote: 'Delete note',
    updatedNoteSuccessfully: 'Updated note successfully',
    writeSomeNote: 'Please write some note',
    addedNoteSuccessfully: 'Added note successfully',
    idNotAvailableForNote: 'ID not available for this note.',
    deletedNoteSuccessfully: 'Deleted note successfully',
    delete: 'Delete'
  },
  scheduleOrSend: {
    title: 'How would you like to schedule these interviews?',
    recSchedule: 'I will schedule the interview',
    recSchedule1: 'We will recommend convenient time slots',
    candidateSchedule: 'I’d like the candidate to schedule this interview',
    candidateSchedule1: 'Send an interview booking link to the candidate',
    scheduleInterview: 'Schedule interview',
    sendEmailWithInterview: 'Send email with interview booking link'
  },
  timelineTab: 'Timeline',
  interviewAndNotesTab: 'Interviews & notes',
  emailsTab: 'Emails',
  profileTab: 'Profile',
  emptyNotesTabText1: 'No interviews or notes yet!',
  emptyNotesTabText2: 'All your interviews, scores or notes used for collaboration will show up here.',
  emptyEmailsTab1: 'No emails exchanged yet!',
  emptyEmailsTab2: 'Send an email or sync your email account to see emails exchanged.',
  emails: {
    email: 'Email',
    offerEmail: 'Offer email',
    sentByAutomationBot: 'sent by automation bot',
    sentBy: 'sent by',
    secretEmail: 'Secret email',
    memberAccessInfo: 'Only members with access can view secret emails.',
    cancelledOn: 'Cancelled on',
    sentOn: 'Sent on',
    receivedOn: 'Received on',
    scheduledOn: 'Scheduled on',
    emailSendFailed: 'Email failed to send.',
    makeVisible: 'Make visible',
    makeSecret: 'Make secret',
    deleteEmail: 'Delete email',
    cancelEmail: 'Cancel email'
  },
  completedOn: 'Completed on',
  collectInfoModule: {
    errorFindingModule: 'Error finding collect information questions.',
    collectInformation: 'Responses to collect information questions',
    secretQuestionsTooltip: 'Only members with access can view secret answer.'
  },
  screeningModule: {
    errorFindingModule: 'Error finding screening questions.',
    screeningQuestions: 'Responses to screening questions',
    mustHave: 'Must have',
    year: 'year',
    years: 'years'
  },
  videoModule: {
    errorFindingModule: 'Error finding video interview responses.',
    videoQuestions: 'Responses to video questions',
    question: 'Question',
    videoNotSupport: 'Your browser does not support the video tag.',
    upNextIn: 'Up next in',
    playNow: 'Play now',
    answers: 'Answers'
  },
  quizModule: {
    errorFindingModule: 'Error finding quiz results.',
    quiz: 'Responses to screening by skill-based test',
    overAllScore: 'OVERALL SCORE',
    pass: 'PASS',
    fail: 'FAIL'
  },
  calendarBookingEvents: {
    scheduledFor: 'Scheduled for',
    interviewTime: 'Interview time',
    reschedule: 'Reschedule',
    edit: 'Edit',
    eventName: 'Event name',
    meetingDuration: 'Meeting duration',
    typeOfMeeting: 'Type of meeting',
    fixedAttendees: 'Fixed attendees',
    interviewers: 'Interviewers',
    rescheduledFor: 'Rescheduled for',
    rescheduleMeeting: 'Reschedule meeting',
    cancelInterviewTitle: 'Cancel the scheduled interview?',
    cancelConfirmationInfoText: 'This action will cancel the scheduled interview. Are you sure?',
    continueToCancel: 'Continue to cancel',
    interviewBookingLink: 'Interview booking link',
    scheduled: 'Scheduled',
    scheduledBy: 'scheduled by',
    by: 'by',
    rescheduledBy: 'rescheduled by',
    sentBy: 'sent by',
    interview: 'Interview',
    cancelledBy: 'cancelled by',
    isCancelled: 'is cancelled',
    automationBot: 'Automation Bot',
    viewCompleteScores: 'View feedback',
    totalScore: 'Total feedback',
    addScore: 'Add feedback',
    noScoresSubmitted: 'Interviewers haven\'t submitted feedback yet',
    submitScoreFeedback: 'After you submit your feedback, you\'ll be able to see what feedback other interviewers have given.',
    addScorecardTemplate: 'Add feedback template',
    scoreCard: 'Feedback template',
    chooseTemplate: 'Choose template',
    scorecardNotNeeded: 'Feedback template not needed'
  },
  resume: {
    resumeTitle: 'Resume',
    uploadTypeInfo: 'Upload the most up-to-date resume. Allowed file types: PDF or Doc.',
    replace: 'Replace file',
    uploadResume: 'Upload resume',
    noResumeFound: 'No resume found',
    resumeViewIssueText: 'Unable to view the file content.',
    clickHereToDownload: 'Click here to download',
    successResumeUploadText: 'Successfully uploaded the resume.',
    resumeUploadSizeError: 'Resume size cannot exceed 5MB!',
    resumeUploadFileTypeError: 'Resume must be a PDF or a Doc!',
    unsupportedContentType: 'Unsupported content type',
    generateSummary: 'Generate summary using AI (beta)',
    summary: 'Summary',
    downloadResume: 'Download resume',
    aiGenerated: 'AI generated (beta)',
    generatingAiSummary: 'Generating AI summary...',
    hideFullResume: 'Hide full resume',
    showFullResume: 'Show full resume',
    downloadOriginalResume: 'Download original resume'
  },
  multipleApplications: {
    totalApplications: 'Total applications',
    addToJob: 'Add to job'
  },
  candidateEmailNotAddedError: 'Candidate email not added. Please add candidate email to proceed.',
  candidateNameNotAddedError: 'Candidate name not added. Please add candidate name to proceed.',
  addCandidateOwner: {
    selectCandidateOwner: 'Select candidate owner',
    candidateOwner: 'Candidate owner',
    search: 'Search'
  },
  askToUploadFile: {
    title: 'Ask to upload a file',
    file: 'Ask a question to upload a file',
    questions: {
      enterQuestion: 'Enter question',
      enterInformation: 'Enter information',
      addAdditionalInfo: 'Add some additional information to help the candidate (optional)',
      fileName: 'File name',
      addAName: 'Add a name',
      pleaseEnter: 'Please enter file name. (e.g., Passport, National ID...)',
      selectFileName: 'Select file name'
    }
  },
  fileSection: {
    showResume: 'Show resume',
    otherFiles: 'Other files',
    candidateUploadedFiles: 'Candidate uploaded files',
    addFiles: 'Add files',
    editFileName: 'Edit file name',
    enterFileName: 'Enter file name',
    messages: {
      fileUploadedSuccessfully: 'File uploaded successfully',
      unableToUpload: 'Unable to upload file',
      fileDeleted: 'File deleted successfully',
      accessDelete: 'You don\'t have access to delete this file',
      madeSecret: 'Successfully made the file secret',
      madePublic: 'Successfully made the file public',
      accessSecret: 'You don\'t have access to make this file secret',
      accessDownload: 'You don\'t have access to download this file',
      pleaseDownload: 'Cannot open this file. Please download',
      accessView: 'You don\'t have access to view this file',
      accessEdit: 'You don\'t have access to edit filename',
      successfullyEdited: 'Successfully edited file name',
      fileSizeError: 'File size cannot exceed 5MB'
    },
    enterFileNameRule: 'Please enter file name',
    toolTips: {
      editFileName: 'Edit file name',
      viewFile: 'View file',
      downloadFile: 'Download file',
      makeItSecret: 'Make file secret',
      makeItPublic: 'Make file public',
      deleteFile: 'Delete file',
      cannotDelete: 'You cannot delete candidate uploaded file',
      secretFile: 'This is a secret file',
      publicFile: 'This is a public file'
    },
    areYouSureToDelete: 'Are you sure to delete file?'
  },
  offerEvent: {
    rejectionReason: 'Rejection reason:',
    comments: 'Comments:',
    offerPreview: 'Offer preview',
    offerSent: '<p class="font-weight-400 text-xs">{who} sent an <span class="font-weight-600">Offer letter</span> to the candidate.</p>',
    offerAccepted: '<p class="font-weight-400 text-xs">{who} signed the <span class="font-weight-600">Offer letter</span></p>',
    offerRejected: '<p class="font-weight-400 text-xs">{who} rejected the <span class="font-weight-600">Offer letter</span></p>',
    sentStatus: 'Pending on candidate to sign',
    candidateSigned: 'Signed by candidate on {date}',
    candidateRejected: 'Rejected by candidate on {date}'
  },
  calendarOrMailNotIntegrated: {
    connectWithCalendar: 'Connect your calendar',
    connectWithMail: 'Connect your mail',
    integrateCalendarWithAts: 'Integrate your calendar with the ATS to schedule interviews directly within the platform.',
    reIntegratedCalendarToManageEvents: 'Your calendar integration has been disconnected. Please reintegrate your calendar with the ATS to manage interview scheduling, including rescheduling or canceling interviews.',
    integrateMailWithAts: 'Integrate your mail with the ATS to send email with interview booking link to the candidate',
    integrateMailWithAtsToSendMail: 'Integrate your mail with the ATS to send email to the candidate'
  }
}

export const CandidateTimelineArabic = {
  goBackToCandidates: 'العودة إلى صفحة المرشحين',
  recruiterActions: {
    resendInvite: 'أعد إرسال الدعوة',
    editApplication: 'تعديل طلب التوظيف',
    moveToJob: 'الانتقال إلى الوظيفة',
    deleteApplication: 'حذف طلب التوظيف',
    unArchive: 'إلغاء الأرشفة',
    resendEmail: {
      sendInvitationAgain: 'هل أنت متأكد أنك تريد إرسال الدعوة مرة أخرى؟',
      invitationEmailNotAdded: 'لم يتم إضافة بريد إلكتروني للدعوة لهذه المرحلة. يرجى تعديل الوظيفة لإضافة بريد إلكتروني للدعوة.',
      sendInvitation: 'أرسل الدعوة'
    },
    editApplicationComp: {
      editCandidate: 'عدّل بيانات المرشح',
      firstNameLabel: 'الاسم الأول',
      firstNamePlaceholder: 'ادخل الاسم الأول للمرشح',
      lastNameLabel: 'اسم العائلة',
      lastNamePlaceholder: 'ادخل اسم عائلة المرشح',
      originLabel: 'المصدر',
      originPlaceholder: 'اختر المصدر',
      sourceLabel: 'المورد',
      sourcePlaceholder: 'اختر المورد'
    },
    addOrMoveToJob: {
      moveCandidateToNewJob: 'انقل المرشح إلى وظيفة جديدة',
      addCandidateToNewJob: 'أضف المرشح إلى وظيفة جديدة',
      chooseJobLabel: 'اختر وظيفة',
      chooseJobPlaceholder: 'حدد وظيفة',
      selectStageLabel: 'حدد المرحلة',
      selectStagePlaceholder: 'حدد المرحلة'
    },
    deleteApplicationComp: {
      dialogTitle: 'هل أنت متأكد من رغبتك في حذف طلب التوظيف؟',
      deleteApplicationButton: 'حذف طلب التوظيف'
    },
    jobNameDropdown: {
      goToCandidateList: 'اذهب إلى قائمة المرشحين',
      openJobDescription: 'فتح وصف الوظيفة',
      editJob: 'تعديل الوظيفة'
    },
    inviteSendConfirmation: {
      cannotUndo: 'لا يمكن التراجع عن هذا الإجراء. هل تريد المتابعة؟'
    },
    sendRejectionEmail: {
      sendRejectionTitle: 'إرسال ايميل رفض',
      emailTemplate: 'نموذج الايميل',
      sendEmailAndReject: 'إرسال البريد الإلكتروني ورفض'
    },
    addEmployeeInBayzat: 'إضافة المرشح كموظف',
    viewEmployeeProfile: 'عرض ملف الموظف',
    addEmployeeInBayzatDialog: {
      formLabels: {
        firstName: 'الاسم الأول',
        lastName: 'اسم العائلة',
        integrationName: 'اسم التكامل',
        nationality: 'الجنسية',
        joiningDate: 'تاريخ الانضمام للشركة',
        phoneContact: 'رقم الهاتف',
        employeeTitle: 'المسمى الوظيفي',
        personalEmail: 'البريد الإلكتروني الشخصي',
        department: 'القسم',
        workEmail: 'البريد الإلكتروني العملي'
      },
      createEmployeeInHRMS: 'إنشاء ملف للموظف في نظام إدارة الموارد البشرية',
      employeeAddingInfo: 'هذا الإجراء سيضيف موظفًا جديدًا على منصة بيزات. يمكنك تسجيل الدخول إلى حسابك في بيزات وإدخال تفاصيل إضافية عن الموظف على ملفه.',
      emailSendingInfo: 'إضافة موظف سترسل ايميلًا إلى مديري الموارد البشرية / مديري نظام تتبع المتقدمين للوظائف / والمدراء المميزين في نظام إدارة الموارد البشرية على منصة بيزات.',
      toKnowMore: 'لمعرفة المزيد عن التكامل.',
      selectNationality: 'رجاءً اختر الجنسية',
      selectIntegration: 'رجاءً اختر التكامل',
      employeeAddedSuccessfully: 'تم إرسال بيانات الموظف بنجاح إلى نظام إدارة الموارد البشرية.'
    },
    addEmployeeInBayzatEvent: {
      creatingEmployeeInHRMS: 'إنشاء موظفين في نظام إدارة الموارد البشرية...',
      failedToAdd: 'تعثر إضافة الموظف إلى نظام إدارة الموارد البشرية على منصة بيزات. رجاءً حاول مرة أخرى.',
      reasonToFail: 'السبب: {reason}',
      addedToHRMS: 'تمت الإضافة إلى نظام إدارة الموارد البشرية'
    },
    archiveAs: 'أرشفة كـ {archivalReason}'
  },
  candidateInfo: {
    contactInformation: 'بيانات الاتصال',
    addInfo: 'أضف معلومات',
    primaryEmailCannotDeleteInfo: 'لا يمكن حذف الايميل الأساسي',
    socialLinks: 'روابط حسابات منصات التواصل الاجتماعي',
    addLink: 'أضف رابط',
    deleteEmail: 'احذف الايميل',
    deletePhoneNumber: 'احذف رقم الهاتف',
    deleteLink: 'احذف الرابط',
    updateEmail: 'حدّث الايميل',
    addEmail: 'أضف ايميل',
    updatePhoneNumber: 'حدّث رقم الهاتف',
    addPhoneNumber: 'أضف رقم هاتف',
    updateLink: 'حدّث الرابط',
    editEmail: 'عدّل الايميل',
    editPhoneNumber: 'عدّل رقم الهاتف',
    editLink: 'عدّل الرابط',
    emailDeleteConfirmation: 'هل أنت متأكد أنك تريد حذف هذا الايميل؟',
    phoneNumberDeleteConfirmation: 'هل أنت متأكد أنك تريد حذف هذا الرقم؟',
    linkDeleteConfirmation: 'هل أنت متأكد أنك تريد حذف هذا الرابط؟',
    phoneNumberPlaceholder: 'ادخل رقم هاتف المرشح',
    emailPlaceholder: 'ادخل ايميل المرشح',
    linkPlaceholder: 'ادخل الرابط',
    mergeApplicationsAndDeleteEmail: 'دمج طلبات التوظيف وتحديث الايميل',
    emailError: 'رجاءً ادخل ايميل صحيح',
    linkError: 'رجاءً ادخل رابط صحيح',
    phoneNumberError: 'رجاءً ادخل رقم هاتف صحيح',
    emailIntegrationNeeded: 'مطلوب ربط الايميل!',
    requireIntegratedEmail: 'نحتاج إلى ربط الايميل لمزامنة كل الايميلات معًا',
    goToSettings: 'اذهب إلى الإعدادات',
    mergeApplicationsConfirmation: 'هناك مرشح موجود بالفعل بهذا الايميل {email} الذي أدخلته. هل تريد دمج طلبات التوظيف معًا لنفس المرشح؟',
    relatedTo: 'متعلق ب{jobRoleName}',
    copyLink: 'نسخ الرابط',
    copyPhoneNumber: 'نسخ رقم الهاتف',
    copyEmail: 'نسخ البريد الإلكتروني',
    syncEmails: 'مزامنة رسائل البريد الإلكتروني للمرشحين',
    contactWhatsApp: 'الاتصال على الواتساب'

  },
  addANote: 'أضف ملاحظة',
  scheduleInterviewTooltip: 'جدولة مقابلة',
  noPermissionToProgress: 'لا تمتلك الصلاحيات لتقدم المرشح',
  noPermissionToArchive: 'لا تمتلك الصلاحيات لأرشفة المرشح',
  sendEmail: 'إرسال ايميل',
  addFeedback: 'إضافة ملاحظات',
  sendOfferTooltip: 'إرسال عرض توظيف',
  addNotes: {
    placeholder: 'أضف ملاحظة هنا مثل: @mention لإرسال رسالة إلى زميل في العمل',
    noResult: 'لم يتم العثور على نتائج',
    secretNoteInfo: 'يمكن للأعضاء ذوي الصلاحية فقط مشاهدة الملاحظات السرية.',
    addNote: 'أضف ملاحظة',
    addedANote: 'أضاف ملاحظة',
    deleteNoteConfirmation: 'هل تريد حذف هذه الملاحظة؟',
    secretNote: 'ملاحظة سرية',
    editNote: 'عدّل الملاحظة',
    deleteNote: 'احذف الملاحظة',
    updatedNoteSuccessfully: 'تم تحديث الملاحظة بنجاح',
    writeSomeNote: 'رجاءً اكتب بعض الملاحظات',
    addedNoteSuccessfully: 'تمت إضافة الملاحظة بنجاح',
    idNotAvailableForNote: 'المعرف غير متوفر لهذه الملاحظة.',
    deletedNoteSuccessfully: 'تم حذف الملاحظة بنجاح',
    delete: 'يمسح'
  },
  scheduleOrSend: {
    title: 'كيف ترغب في جدولة هذه المقابلات؟',
    recSchedule: 'سأجدول المقابلة',
    recSchedule1: 'سنوصي بأوقات مناسبة',
    candidateSchedule: 'أود أن يقوم المرشح بجدولة هذه المقابلة',
    candidateSchedule1: 'أرسل رابط حجز المقابلة إلى المرشح',
    scheduleInterview: 'جدولة مقابلة',
    sendEmailWithInterview: 'أرسل ايميل برابط حجز المقابلة'
  },
  timelineTab: 'الجدول الزمني',
  interviewAndNotesTab: 'المقابلات والملاحظات',
  emailsTab: 'الايميلات',
  profileTab: 'حساب تعريفي',
  emptyNotesTabText1: 'لا توجد مقابلات أو ملاحظات بعد.!',
  emptyNotesTabText2: 'ستظهر هنا جميع المقابلات أو النتائج أو الملاحظات المستخدمة للتعاون.',
  emptyEmailsTab1: 'لم يتم تبادل أي ايميلات بعد.',
  emptyEmailsTab2: 'أرسل ايميلًا أو زامن ايميلك مع المنصة لرؤية الايميلات المتبادلة.',
  emails: {
    email: 'الايميل',
    offerEmail: 'رسالة عرض التوظيف',
    sentByAutomationBot: 'أرسل الايميل من خلال بوت ',
    sentBy: 'أُرسل بواسطة',
    secretEmail: 'ايميل سري',
    memberAccessInfo: 'يمكن للأعضاء ذوي الصلاحية فقط مشاهدة الرسائل السرية.',
    cancelledOn: 'أُلغي في',
    sentOn: 'أُرسل في',
    receivedOn: 'وردت في',
    scheduledOn: 'مُجدول في',
    emailSendFailed: 'فشل إرسال الايميل',
    makeVisible: 'جعله مرئيًا',
    makeSecret: 'جعله سريًا',
    deleteEmail: 'احذف الايميل',
    cancelEmail: 'إلغاء الايميل'
  },
  completedOn: 'اكتمل في',
  collectInfoModule: {
    errorFindingModule: 'حدث خطأ في العثور على أسئلة جمع البيانات.',
    collectInformation: 'إجابات لأسئلة جمع المعلومات',
    secretQuestionsTooltip: 'يمكن للأعضاء ذوي الصلاحية فقط مشاهدة الإجابات السرية.'
  },
  screeningModule: {
    errorFindingModule: 'حدث خطأ في العثور على أسئلة الفحص المبدئي.',
    screeningQuestions: 'ردود على أسئلة الفحص',
    mustHave: 'يجب أن يكون لديه',
    year: 'سنة',
    years: 'سنوات'
  },
  videoModule: {
    errorFindingModule: 'خطأ حدث في العثور على ردود المقابلة',
    videoQuestions: 'ردود على أسئلة الفيديو',
    question: 'السؤال',
    videoNotSupport: 'متصفحك لا يدعم تشغيل الفيديو.',
    upNextIn: 'التالي في',
    playNow: 'شغل الآن',
    answers: 'الإجابات'
  },
  quizModule: {
    errorFindingModule: 'حدث خطأ في العثور على نتائج اختبار التوظيف',
    quiz: 'الردود على الفحص عن طريق الاختبار القائم على المهارات',
    overAllScore: 'النتيجة الإجمالية',
    pass: 'نجاح',
    fail: 'رسوب'
  },
  calendarBookingEvents: {
    scheduledFor: 'مُجدول لـ',
    interviewTime: 'وقت المقابلة',
    reschedule: 'إعادة جدولة',
    edit: 'تعديل',
    eventName: 'عنوان المقابلة ',
    meetingDuration: 'مدة المقابلة',
    typeOfMeeting: 'نوع المقابلة',
    fixedAttendees: 'الحاضرون الدائمون',
    interviewers: 'المحاورين',
    rescheduledFor: 'أُعيد جدولته لـ',
    rescheduleMeeting: 'إعادة جدولة الاجتماع',
    cancelInterviewTitle: 'هل تريد إلغاء المقابلة المرتبة؟',
    cancelConfirmationInfoText: 'سيؤدي هذا الإجراء إلى إلغاء المقابلة المرتبة. هل أنت متأكد؟',
    continueToCancel: 'استمر في الإلغاء',
    interviewBookingLink: 'رابط حجز المقابلة',
    scheduled: 'مُجدول',
    scheduledBy: 'مُجدول بواسطة',
    by: 'بواسطة',
    rescheduledBy: 'أُعيد جدولته بواسطة',
    sentBy: 'أُرسل بواسطة',
    interview: 'مقابلة',
    cancelledBy: 'أُلغي بواسطة',
    isCancelled: 'تم إلغاء',
    automationBot: 'بوت',
    viewCompleteScores: 'عرض ردود الفعل',
    totalScore: 'إجمالي التعليقات',
    addScore: 'أضف تعليقات',
    noScoresSubmitted: 'لم يقدم القائمون على المقابلة تعليقاتهم بعد',
    submitScoreFeedback: 'بعد إرسال تعليقاتك، ستتمكن من رؤية التعليقات التي قدمها القائمون على المقابلة الآخرون.',
    addScorecardTemplate: 'إضافة قالب ردود الفعل',
    scoreCard: 'قالب ردود الفعل',
    chooseTemplate: 'اختر القالب',
    scorecardNotNeeded: 'قالب ردود الفعل ليست هناك حاجة'
  },
  resume: {
    resumeTitle: 'سيرة ذاتية',
    uploadTypeInfo: 'قم بتحميل أحدث سيرة ذاتية. الأنواع المسموحة: PDF أو Doc.',
    replace: 'استبدل الملف',
    uploadResume: 'رفع السيرة الذاتية',
    noResumeFound: 'لم يتم العثور على السيرة الذاتية',
    resumeViewIssueText: 'تعذر عرض محتوى الملف.',
    clickHereToDownload: 'اضغط هنا للتحميل',
    successResumeUploadText: 'تم رفع السيرة الذاتية بنجاح.',
    resumeUploadSizeError: 'لا يمكن أن يتجاوز حجم السيرة الذاتية 5 ميغابايت!',
    resumeUploadFileTypeError: 'الصيغ المسموحة للسيرة الذاتية PDF أو Doc فقط.',
    unsupportedContentType: 'نوع المحتوى غير مدعوم',
    generateSummary: 'إنشاء ملخص باستخدام الذكاء الاصطناعي (نسخة تجريبية)',
    summary: 'الملخص',
    downloadResume: 'تحميل السيرة الذاتية',
    aiGenerated: 'مُنشأ بواسطة الذكاء الاصطناعي (نسخة تجريبية)',
    generatingAiSummary: 'جارِ إنشاء الملخص بواسطة الذكاء الاصطناعي...',
    hideFullResume: 'إخفاء السيرة الذاتية كاملة',
    showFullResume: 'عرض السيرة الذاتية كاملة',
    downloadOriginalResume: 'تحميل السيرة الذاتية الاصلية'
  },
  multipleApplications: {
    addToJob: 'أضف إلى الوظيفة',
    totalApplications: 'إجمالي طلبات التوظيف '
  },
  candidateEmailNotAddedError: 'لم يتم إضافة ايميل المرشح. رجاءً أضف ايميل المرشح للمتابعة.',
  candidateNameNotAddedError: 'لم يتم إضافة اسم المرشح. يرجى إضافة اسم المرشح للمتابعة.',
  addCandidateOwner: {
    selectCandidateOwner: 'حدد المالك المرشح',
    candidateOwner: 'مالك المرشح',
    search: 'يبحث'
  },
  askToUploadFile: {
    title: 'اسأل لتحميل ملف',
    file: 'سؤال لتحميل ملف',
    questions: {
      enterQuestion: 'أدخل السؤال',
      enterInformation: 'أدخل المعلومات',
      addAdditionalInfo: 'أضف بعض المعلومات الإضافية لمساعدة المرشح (اختياري)',
      fileName: 'اسم الملف',
      addAName: 'أضف اسماً',
      pleaseEnter: 'الرجاء إدخال اسم الملف. (مثال: جواز السفر، الهوية الوطنية...)'
    }
  },
  fileSection: {
    showResume: 'عرض السيرة الذاتية',
    otherFiles: 'ملفات أخرى',
    candidateUploadedFiles: 'قام المرشح بتحميل الملفات',
    addFiles: 'إضافة ملفات',
    editFileName: 'تعديل اسم الملف',
    enterFileName: 'أدخل اسم الملف',
    messages: {
      fileUploadedSuccessfully: 'تم رفع الملف بنجاح',
      unableToUpload: 'تعذر رفع الملف',
      fileDeleted: 'تم حذف الملف بنجاح',
      accessDelete: 'ليس لديك الصلاحية لحذف هذا الملف',
      madeSecret: 'تم جعل الملف سريًا بنجاح',
      madePublic: 'تم نشر الملف بنجاح',
      accessSecret: 'ليس لديك الصلاحية لجعل هذا الملف سريًا',
      accessDownload: 'ليس لديك الصلاحية لتنزيل هذا الملف',
      pleaseDownload: 'لا يمكن فتح هذا الملف. الرجاء التنزيل',
      accessView: 'ليس لديك الصلاحية لعرض هذا الملف',
      accessEdit: 'ليس لديك الصلاحية لتعديل اسم الملف',
      successfullyEdited: 'تم تعديل اسم الملف بنجاح',
      fileSizeError: 'لا يمكن أن يتجاوز حجم الملف 5 ميغابايت'
    },
    enterFileNameRule: 'الرجاء إدخال اسم الملف',
    toolTips: {
      editFileName: 'تعديل اسم الملف',
      viewFile: 'عرض الملف',
      downloadFile: 'تحميل الملف',
      makeItSecret: 'جعل الملف سريًا',
      makeItPublic: 'اجعل الملف عامًا',
      deleteFile: 'حذف الملف',
      cannotDelete: 'لا يمكنك حذف الملف الذي تم تحميله للمرشح',
      secretFile: 'هذا ملف سري',
      publicFile: 'هذا ملف عام'
    },
    areYouSureToDelete: 'هل أنت متأكد من حذف الملف؟'
  },
  offerEvent: {
    rejectionReason: 'سبب الرفض:',
    comments: 'التعليقات:',
    offerPreview: 'معاينة العرض',
    offerSent: '<p class="font-weight-400 text-xs">{who} أرسل <span class="font-weight-600">خطاب العرض</span> إلى المرشح.</p>',
    offerAccepted: '<p class="font-weight-400 text-xs">{who} وقع <span class="font-weight-600">خطاب العرض</span></p>',
    offerRejected: '<p class="font-weight-400 text-xs">{who} رفض <span class="font-weight-600">خطاب العرض</span></p>',
    sentStatus: 'في انتظار توقيع المرشح',
    candidateSigned: 'تم التوقيع من قبل المرشح في {date}',
    candidateRejected: 'تم الرفض من قبل المرشح في {date}'
  },
  calendarOrMailNotIntegrated: {
    connectWithCalendar: 'الاتصال بالتقويم',
    connectWithMail: 'الاتصال بالبريد',
    integrateCalendarWithAts: 'قم بدمج التقويم الخاص بك مع نظام ATS لجدولة المقابلات مباشرةً داخل المنصة.',
    reIntegratedCalendarToManageEvents: 'تم فصل تكامل التقويم الخاص بك. يرجى إعادة دمج التقويم مع نظام ATS لإدارة جدولة المقابلات، بما في ذلك إعادة الجدولة أو إلغاء المقابلات.',
    integrateMailWithAts: 'قم بدمج بريدك الإلكتروني مع نظام ATS لإرسال رابط حجز المقابلة إلى المرشح.',
    integrateMailWithAtsToSendMail: 'قم بدمج بريدك الإلكتروني مع نظام ATS لإرسال بريد إلكتروني إلى المرشح.'
  }
}
